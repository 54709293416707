import React from "react";

const Privacy = () => {
  return (
    // <div className="min-h-screen py-40 max-w-[1500px] lg:max-w-[768px] mx-auto font-readex_regular px-4">
    //   <h1 className="font-readex_medium md:text-[24px] text-[44px] text-center capitalize">
    //     Privacy <span className="text-[#3E46FE]">Policy</span>
    //   </h1>
    //   <h2 className="font-readex_medium mt-5 underline">
    //     EFFECTIVE DATE: [8/5/2025]
    //   </h2>

    //   <p className="mt-5 text-lg">
    //     Thank you for choosing Allignx (“we”, “us”, “our”). Being a part of the
    //     Allignx community means behaving with honesty, respect and kindness to
    //     fellow users. All users (“user”, “you”, “your”) including Professionals,
    //     Users, and visitors of the Allignx Platform are required to follow the
    //     terms of this Code of Conduct (“Agreement”). You hereby agree to the
    //     following terms as follows:
    //   </p>

    //   <h2 className="font-readex_medium mt-10 text-3xl ">Be Courteous</h2>
    //   <p className="mt-5 text-lg">
    //     You agree to be courteous and respectful of others. You shall not use
    //     words or share content which may be inflammatory or offensive.
    //   </p>
    //   <p className="mt-5 text-lg">
    //     We strictly prohibit unlawful discrimination or harassment on the basis
    //     of race, color, religion, veteran status, national origin, ancestry,
    //     pregnancy status, sex, gender identity or expression, age, marital
    //     status, mental or physical disability, medical condition, sexual
    //     orientation, or any other characteristics protected by law.
    //   </p>
    //   <p className="mt-5 text-lg">
    //     Communicating online through text can lead to misunderstandings, so it
    //     is important to give other people the benefit of the doubt and be kind
    //     in your interactions: sometimes it’s difficult to know or fully
    //     appreciate the situation of the person you are communicating with.
    //   </p>
    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       harassment, discrimination, and bullying
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       We strictly prohibit discrimination, harassment and other forms of
    //       bullying including without limits verbal, physical, or visual. If you
    //       believe that you are being bullied by anyone, whether by other users
    //       or by Allignx personnel, we recommend you to immediately report the
    //       incident to us using the contact information given below or contact
    //       the appropriate authorities. We will take action against such persons
    //       at our sole discretion.
    //     </p>
    //   </div>

    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       Drugs and Alcohol
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       We do not permit you to interact, and carry out/receive services while
    //       under the influence of any substances including prohibited or
    //       regulated substances and alcohol. Use of drugs and alcohol results in
    //       impaired judgement and performance. If you suspect that anyone you
    //       come in contact through the Allignx Platform is under influence of any
    //       substance or alcohol, please reach out to us immediately upon such
    //       discovery or contact the appropriate authorities.
    //     </p>
    //   </div>

    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       improper payments
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       We request all users to make appropriate payments solely through the
    //       Allignx Platform. Please do not make any payments outside of the
    //       Allignx Platform in any form.
    //     </p>
    //   </div>
    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       Threat of bodily harm
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       We do not permit users under any circumstances to send threats of
    //       bodily harm, whether through the Platform (if applicable) or otherwise
    //       via any method including, phone, email etc. Upon our discovery of such
    //       an event, we may terminate and permanently ban the user Accounts of
    //       such users.
    //     </p>
    //   </div>
    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       Use of any physical force against Allignx personnel and other users
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       No person shall subject the directors, employees, affiliates, agents,
    //       representatives or subcontractors of Allignx and other users of the
    //       Allignx Platform to any physical harassment, intimidation, threats,
    //       coercion, confinement or use of criminal force, during the course of
    //       their business or in connection with the business. Any such act would
    //       lead to the consequences mentioned in 10 of this Agreement. This shall
    //       be in addition to and without prejudice to all our additional legal
    //       remedies.
    //     </p>
    //   </div>

    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       Compliance with the Law
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       You are law-abiding and do not participate in, condone or encourage
    //       unlawful or potentially harmful activity.
    //     </p>
    //     <p className="mt-5 text-lg">
    //       This includes threatening or encouraging suicide or self-harm, as well
    //       as breach of copyright, defamation, or contempt of court.
    //     </p>
    //   </div>

    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       Misuse of the Platform
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       You shall not use the Platform in a way which may be considered a
    //       misuse of the services provided by us through the Platform. Please
    //       contact us using the information given below if you discover any user
    //       misusing the Platform. In such event, we will investigate your report
    //       and take appropriate action in our sole discretion in accordance with
    //       Section 10 of this Agreement.
    //     </p>
    //   </div>
    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       taking action
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       We continually strive to improve our services. If you feel that the
    //       services provided to you by us or the Professionals is inadequate,
    //       please inform us using the contact information given below.
    //     </p>
    //   </div>
    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       Effects of violation of this Agreement
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       Violations of this Agreement may result in a range of actions,
    //       including:
    //     </p>
    //     <ul className="list-disc list-inside space-y-4 mt-4">
    //       <li>Limits on account privileges</li>
    //       <li>Account suspension</li>
    //       <li>Cancellation of listing</li>
    //       <li>Account Cancellation</li>
    //       <li>Permanent ban on the access and use of the Platform.</li>
    //     </ul>
    //     <p className="mt-5 text-lg">
    //       The actions in this Section shall be in addition to and without
    //       prejudice to all our additional legal remedies.
    //     </p>
    //   </div>

    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       Non-Disparagement
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       The users shall not make any false, negative, critical or disparaging
    //       statements, implied or expressed about Allignx, including, but not
    //       limited to, management style, methods of doing business, the quality
    //       of services, role in the community, or treatment of employees. The
    //       users further agree to do nothing that would damage our reputation or
    //       good will;
    //     </p>
    //   </div>

    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">PETS</h2>
    //     <p className="mt-5 text-lg">
    //       If you have pets or other service animals, please inform the
    //       Professionals you engage via the Allignx prior to engaging their
    //       services.
    //     </p>
    //   </div>

    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       No Spamming
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       This Platform’s spam policy applies only to unsolicited commercial
    //       messages sent you. You are not allowed to send spam messages to other
    //       Users.
    //     </p>
    //   </div>
    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       Reporting spam
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       You will help us by reporting comments that you believe have violated
    //       this Agreement.
    //     </p>
    //     <p className="mt-5 text-lg">
    //       You can report a comment by clicking on “Report” button on the User
    //       Profile of the spammer. You can also report spam by sending us an
    //       email at: support@allignx.com.
    //     </p>
    //   </div>
    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       Claims of copyright infringement
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       Claims of copyright infringement Claims of copyright infringements can
    //       be sent to [support@allignx.com].
    //     </p>
    //   </div>
    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       severability
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       If one or more provisions of this Agreement is found to be unlawful,
    //       void or unenforceable, such provision(s) shall be deemed severable and
    //       will not affect the validity and/or enforceability of the remaining
    //       provisions of the Agreement, which will remain in full force and
    //       effect.
    //     </p>
    //   </div>
    //   <div>
    //     <h2 className="font-readex_medium mt-10 text-3xl capitalize">
    //       contact
    //     </h2>
    //     <p className="mt-5 text-lg">
    //       Please reach out to us at: E-mail: [support@allignx.com]
    //     </p>
    //   </div>
    // </div>

    <div className="min-h-screen py-40 max-w-[1300px] lg:max-w-[768px] mx-auto font-readex_regular px-4">
      <h1 className="font-readex_medium md:text-[24px] text-[44px] text-center capitalize">
        Privacy <span className="text-[#3E46FE]">Policy</span>
      </h1>
      <h2 className="font-readex_medium mt-5 text-3xl underline">
        INTRODUCTION
      </h2>

      <p className="mt-5 text-lg">
        We are delighted that you have shown interest in the services of Allignx (hereby collectively referred to as “we”, “us”, “our”) which are provided via the Allignx mobile application, and website (hereinafter collectively referred to as the “Platform”). Data protection is our utmost priority. The use of the Platform is possible without any indication of personal data; however, if a data subject wants to use special services via our Platform, processing of personal data may become necessary. If the processing of personal data is necessary, and there is no statutory basis for such processing, we generally obtain consent from the data subject.
        The processing of personal data, such as the name, address, GPS location information, e-mail address, or telephone number of a data subject shall always be in line with applicable data protection laws and in accordance with the applicable specific data protection regulations applicable to us. This Privacy Policy for U.S. States pertains to individuals defined as "Consumers" under various U.S. privacy laws, including the California Consumer Privacy Act (CCPA), the Virginia Consumer Data Protection Act, the Utah Consumer Privacy Act, Connecticut's Act Concerning Personal Data Privacy, Chapter 603A of the Nevada Revised Statutes, and any related laws, regulations, or amendments (collectively referred to as "U.S. Privacy Laws"). Utilizing this data protection declaration, we would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed through this data protection declaration, of the rights to which they are entitled.
        As the controller, Allignx has implemented numerous technical and organizational measures to ensure the complete protection of personal data processed through this Platform. However, Internet-based data transmissions may in principle have security gaps, so absolute protection may not be guaranteed. For this reason, every data subject is free to transfer personal data to us via alternative means, e.g. by telephone.

      </p>

      <h2 className="font-readex_medium mt-10 text-3xl ">OVERVIEW OF DATA HANDLING PRACTICES</h2>
      <p className="mt-5 text-lg">
        The depiction of our data handling practices within this Privacy Policy encompasses the preceding twelve (12) months leading up to the "Last Updated" date and will be revisited at least annually. Additionally, this Privacy Policy extends to our present data practices, serving as a form of "notice at collection" for your benefit. In the event of any novel or significantly distinct processing activities not delineated in this Privacy Policy, we will adhere to the requirements stipulated by U.S. Privacy Laws. This may involve furnishing supplementary information at the point of data collection or revising this Privacy Policy accordingly.
      </p>

      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          WHAT PERSONAL DATA DO WE COLLECT ABOUT YOU?
        </h2>
        <p className="mt-5 text-lg">
          We collect personal data from you when you provide it to us directly and through your use of the Platform. This information may include:
        </p>
        <ul className="list-disc list-inside space-y-4 mt-4">
          <li>Information you provide to us when you use our Platform (e.g. your name, contact details, and any information which you add to your account profile);</li>
          <li>Transaction and billing information (collected via third-party payment processors), if you make any purchases from us or using our Platform (e.g. credit/debit card details and delivery information);</li>
          <li>Records of your interactions with us (e.g. if you contact our customer service team, interact with us on social media);</li>
          <li>Information you provide us when you enter a competition or participate in a survey;</li>
          <li>Information collected automatically, using tracking technologies (e.g. which pages you viewed and whether you clicked on a link in one of our email updates). We may also collect information about the device you use to access our Platform; and</li>
          <li>Other information necessary to provide the Platform, for example we may access your location if you give us your consent.</li>
        </ul>

        <p>
          In addition, we may collect the following information from both the Users and Service Providers :
        </p>
        <div class="flex flex-col space-y-8 p-8 text-black">

          <table class="min-w-full border-2 ">
            <thead >
              <tr>
                <th class="px-4 py-2  font-bold border-2 ">Users</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="px-4 py-2  border-2 ">Phone number</td>
              </tr>
              <tr>
                <td class="px-4 py-2  border-2 ">Email Address</td>
              </tr>
            </tbody>
          </table>


          <table class="min-w-full border-2 ">
            <thead >
              <tr>
                <th class="px-4 py-2  font-bold border-2 ">Service Providers</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="px-4 py-2  border-2 ">Phone number</td>
              </tr>
              <tr>
                <td class="px-4 py-2  border-2 ">Email Address</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          PROTECTION OF MINORS' PRIVACY
        </h2>
        <p className="mt-5 text-lg">
          Depending on how you use our Platform, your interactions with us, and the permissions you give us, the purposes for which we use your personal data include:
        </p>
      </div>

      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          WHAT DO WE USE THIS PERSONAL DATA FOR?
        </h2>
        <p className="mt-5 text-lg">
          We request all users to make appropriate payments solely through the
          Allignx Platform. Please do not make any payments outside of the
          Allignx Platform in any form.
        </p>
        <ul className="list-disc list-inside space-y-4 mt-4">
          <li>To maintain your online account.</li>
          <li>To manage and respond to any queries or complaints to our customer service team.</li>
          <li>To personalise the Platform to you and show you content we think you will be most interested in, based on your account information, your purchase history and your browsing activity.</li>
          <li>To improve and maintain the Platform and monitor its usage.</li>
          <li>For market research, e.g. we may contact you for feedback about our products.</li>
          <li>To send you marketing messages and show you targeted advertising, where we have your consent or are otherwise permitted to do so.</li>
          <li>For security purposes, to investigate fraud and where necessary to protect ourselves and third parties..</li>
          <li>To comply with our legal and regulatory obligations.</li>
        </ul>
        <p className="mt-5 text-lg">
          We rely on the following legal basis, under data protection law, to process your personal data:
        </p>
        <ul className="list-disc list-inside space-y-4 mt-4">
          <li>Because the processing is necessary to perform a contract with you or take steps prior to entering into a contract with you (e.g. where you have made a purchase with us, our partner third-party payment processors use your personal data to process the payment and complete your transaction).</li>
          <li>Because we have obtained your consent (e.g. where you contact us with a query, where you add optional information to your account profile, or if you consent to receive marketing from us).</li>
          <li>We use information about you to tailor your view of the Platform, to make it more interesting and relevant in respect of the offers on view.</li>
        </ul>
      </div>
      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          FINANCIAL DETAILS
        </h2>
        <p className="mt-5 text-lg">
          In order to complete a purchase, you may be required to furnish a valid payment method such as a credit card. Your payment data will be gathered and processed by our authorized payment partners. As part of our standard transaction processing procedures, we do not directly acquire or retain credit or debit card numbers. Should we facilitate purchases through a third-party platform (e.g., in-app purchases), the payment method registered with that platform will be utilized for billing. Should you opt for payment via bank transfer, you will need to supply the relevant bank account particulars.
        </p>
      </div>
      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          MARKETING
        </h2>
        <p className="mt-5 text-lg">
          Depending upon your marketing preferences, we may use your personal data to send you marketing messages by email, phone or post. Some of these messages may be tailored to you, based on your previous browsing or purchase activity, and other information we hold about you.
        </p>
        <p className="mt-5 text-lg">
          If you no longer want to receive marketing communications from us (or would like to opt back in!), you can change your preferences at any time by contacting us via support@allign.com. Clicking on the ‘unsubscribe’ link in any email, or updating your settings in your account. If you unsubscribe from marketing, please note we may still contact you with service messages from time to time (e.g. order and delivery confirmations, and information about your legal rights).
        </p>
        <p className="mt-5 text-lg">
          You may also see ads for our Platform on third party websites, including on social media. These ads may be tailored to you using cookies (which track your web activity, so enable us to serve ads to customers who have visited our Platform). Where you see an ad on social media, this may because we have engaged the social network to show ads to our customers, or users who match the demographic profile of our customers. In some cases, this may involve sharing your email address with the social network. If you no longer want to see tailored ads you can change your cookie and privacy settings on your browser and these third-party websites.
        </p>
      </div>

      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          WHO DO WE SHARE THIS PERSONAL DATA WITH?
        </h2>
        <p className="mt-5 text-lg">
          We share customers’ personal data with third parties in the following circumstances:
        </p>
        <ul className="list-disc list-inside space-y-4 mt-4">
          <li>With other companies as necessary to operate the Platform.</li>
          <li>With our suppliers and service providers working for us, e.g. payment processors.</li>
          <li>With our professional and legal advisors.</li>
          <li>With third parties engaged in fraud prevention and detection.</li>
          <li>With law enforcement or other governmental authorities, e.g. to report a fraud or in response to a lawful request.</li>
        </ul>
        <p className="mt-5 text-lg">
          If we sell any business assets, the personal data of our customers may be disclosed to a potential buyer. In this event, we will make reasonable attempts to ensure the buyer will be bound by the terms of this Privacy Policy. Otherwise where we have your consent or are otherwise legally permitted to do so.
        </p>
      </div>

      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          STORAGE AND RETENTION
        </h2>
        <p className="mt-5 text-lg">
          We will keep your personal data for as long as we need it for the purposes set out above, and so this period will vary depending on your interactions with us. For example, where you have made a purchase through the Platform, we will keep a record of your purchase for the period necessary for invoicing, tax and warranty purposes. We may also keep a record of correspondence with you (for example if you have made a complaint about a product) for as long as is necessary to protect us from a legal claim. Where we no longer have a need to keep your information, we will delete it. Please note that where you unsubscribe from our marketing communications, we will keep a record of your email address to ensure we do not send you marketing emails in future.
        </p>
      </div>
      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          SAFEGUARDING YOUR INFORMATION
        </h2>
        <p className="mt-5 text-lg">
          We implement a combination of physical, technical, and organizational security measures to protect your data from unauthorized access or inadvertent disclosure. However, despite our best efforts, no information system can guarantee absolute security, so we cannot provide a 100% assurance of the security of your information. Users also bear responsibility for ensuring the safety of their data.
        </p>
        <p className="mt-5 text-lg">
          We encourage you to utilize a unique and difficult-to-guess password for your account and refrain from sharing it with others. Grant access rights only to individuals whom you know and trust, exercising caution even in granting such access. Regularly monitor your account activity. If you suspect unauthorized access to your account, please contact us immediately for investigation.
        </p>
      </div>
      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          YOUR RESPONSIBILITIES
        </h2>
        <p className="mt-5 text-lg">
          You may receive other people's data in using our service. If you receive information from other users, you must process that information in compliance with applicable laws, including those concerning privacy, data security, and online marketing.
        </p>
      </div>

      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          LEGAL AND SAFETY-RELATED INFORMATION DISCLOSURE
        </h2>
        <p className="mt-5 text-lg">
          We reserve the right to disclose your data in response to official requests, such as court orders, subpoenas, search warrants, or national security requests, received from government authorities or parties involved in legal proceedings ("requests").
        </p>
        <p className="mt-5 text-lg">
          For requests originating from foreign jurisdictions, we typically adhere to a standard where we disclose information in good faith, provided it complies with both U.S. law and local regulations. In all instances, we retain the discretion to raise or waive any legal objections or rights available to us.
        </p>
        <p className="mt-5 text-lg">
          In situations where there is a reasonable belief that an individual's life is in jeopardy, such as instances of suicide threats, we may disclose user data to relevant entities capable of providing assistance.
        </p>
        <p className="mt-5 text-lg">
          User data may also be disclosed to report suspected criminal activities, including the exploitation of minors, to the National Center for Missing and Exploited Children (NCMEC), along with identifying information about the user responsible for uploading such content.
        </p>
        <p className="mt-5 text-lg">
          In cases involving legal claims against either our company or one of our users, we reserve the right to disclose user data. This may include sharing Digital Millennium Copyright Act (DMCA) or other takedown notices with affected users, and if challenged, sharing the user's response with the complainant.
        </p>
        <p className="mt-5 text-lg">
          Additionally, in the event of a potential acquisition of our company, either in part or in whole, by a third party, we may share your data with potential transaction partners, advisors, and other relevant parties. We will endeavor to ensure that the acquiring entity agrees to adhere to the terms outlined in this Privacy Policy to the best of our ability.
        </p>
      </div>

      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">	YOUR PRIVACY OPTIONS</h2>
        <p className="mt-5 text-lg">
          We afford you various options regarding your data:
        </p>
        <ul className="list-disc list-inside space-y-4 mt-4">
          <li>You have the option to refrain from providing certain information. For instance, you can choose not to create an account or decline to provide optional account details.</li>
          <li>You have the ability to adjust your account privacy settings.</li>
          <li>You can modify or rectify voluntarily submitted information. We recommend keeping your data up to date by accessing your settings page.</li>
          <li>You can opt out of receiving promotional emails from us.</li>
          <li>You have the option to close your account, which will result in the deletion of all associated account data.</li>
        </ul>
      </div>

      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          MOBILE DEVICE COMMUNICATIONS
        </h2>
        <p className="mt-5 text-lg">
          We may, upon obtaining your consent, send push notifications through our Platform. You can opt out of these notifications by declining them or adjusting the Platform's settings accordingly. Additionally, subject to your consent, we may send SMS messages to your mobile phone for authentication and security reasons. You have the option to opt out of receiving such messages at any time.
        </p>
      </div>
      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          CONSUMER RIGHTS
        </h2>
        <p className="mt-5 text-lg">
          We extend the privacy rights described in this section to Consumers (users who are residents of certain states, as defined above). For residents of states without specific Consumer privacy rights, we will still entertain requests but retain the discretion to determine how we handle such requests.
        </p>

      </div>
      <div>
        <h4 className="font-readex_medium mt-10 text-2xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Initiating a Request and Request Scope
            </li>
          </ul>
        </h4>
        <p className="mt-5 text-lg">
          Any request you submit to us is subject to an identity verification process ("Verifiable Consumer Request"), as outlined in the Verification of Your Request section below.
        </p>
        <p className="mt-5 text-lg">
          To submit a request, excluding a Do Not Sell/Share/Target request, please use one of the following methods:
        </p>
        <p className="mt-5 text-lg">Send an email to support@allignx.com</p>
        <p className="mt-5 text-lg">For instructions on submitting a Do Not Sell/Share/Target request, refer to the respective section below.</p>
        <p className="mt-5 text-lg">Certain information we retain about Consumers, although technically considered Personally Identifiable Information (“PII”), may not be adequately associated with the information provided by you when making the request. For instance, if you provide your name and email address when submitting a request, we may be unable to link that information to certain data collected on the Platform. In cases where such association cannot be established, we are consequently unable to link such information to you and therefore cannot include it in our response to those requests. If compliance with a request is not feasible, we will elucidate the reasons in our response.</p>
        <p className="mt-5 text-lg">We will exert commercially reasonable efforts to identify PII that we collect, process, store, disclose, and otherwise utilize, and to address your privacy-related inquiries. Typically, we do not impose a fee for fully addressing your requests; however, in certain circumstances permitted by U.S. Privacy Laws, we reserve the right to charge a reasonable fee or decline to act upon a request if it is excessive, repetitive, unfounded, or overly burdensome. Should we deem a fee necessary or opt to refuse a request, we will provide notice explaining the rationale behind our decision in our response to you. You will receive a cost estimate and the opportunity to accept such fees before any charges are incurred for addressing your request.</p>
      </div>
      <div>
        <h4 className="font-readex_medium mt-10 text-2xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Verification of Your Request
            </li>
          </ul>
        </h4>
        <p className="mt-5 text-lg">
          Upon submission of a request, we will verify your identity to ensure that you are indeed the individual you claim to be. If you are acting on behalf of another person, we will verify that you are authorized to make the request on their behalf (refer to our "Authorization of an Agent" section below). Additionally, we will cross-reference the information provided by you to confirm the presence of Personally Identifiable Information (PII) associated with you in our systems. Initially, we request that you provide us with, at a minimum, your full name and email address. Depending on the nature of the request and the availability of the provided email address in our records, we may require further information from you to verify your identity as the requester. We will assess the information furnished as part of your request and may solicit additional details via email or alternative means to finalize the verification process. Your Right to Know, Right to Know, Right to Delete, or Right to Correction request will only be fulfilled once we have received adequate information to reasonably verify that you are the Consumer referenced in the request. However, opt-outs of Sharing, or limitations of Sensitive PII requests, do not undergo the same verification process, although we may implement authentication measures if fraud is suspected (such as confirming access to the provided email address).
        </p>
        <p className="mt-5 text-lg">The verification criteria we apply for each request type may differ. We verify category requests and certain deletion and correction requests (those of less sensitive nature) to a reasonable degree of certainty. This may involve matching at least two data points provided by you with our maintained data points, which we have deemed reliable for verification purposes. For deletion and correction requests concerning more sensitive PII, as well as specific pieces requests, we apply a verification standard of reasonably high certainty. This entails matching at least three data points provided by you with our maintained data points, deemed reliable for verification purposes. Additionally, it may involve obtaining a signed declaration from you, under penalty of perjury, confirming your identity as the individual whose PII is the subject of the request.</p>
        <p className="mt-5 text-lg">If we are unable to verify you for certain requests due to incomplete or insufficient information, we will still undertake certain actions as mandated by applicable U.S. Privacy Laws. For instance, if you are a California Consumer:</p>
        <p className="mt-5 text-lg">In the event of an unverifiable deletion request, we will direct you to this Privacy Policy for a general outline of our data practices.
        </p>
        <p className="mt-5 text-lg">If we cannot verify your specific pieces request, we will treat it as a categories request.</p>
      </div>
      <div>
        <h4 className="font-readex_medium mt-10 text-2xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Authorization of an Agent
            </li>
          </ul>
        </h4>
        <p className="mt-5 text-lg">

          You have the option to designate an authorized agent to submit a Consumer request on your behalf. To validate the authority of an agent submitting a request on your behalf, sufficient evidence must be provided demonstrating that you have granted authorization. This may include, at a minimum, evidence of signed permission to submit the request. Additionally, in accordance with U.S. Privacy Laws, we may require you, the Consumer, to either verify your own identity or directly confirm with us that you have provided permission to the agent to submit the request.
        </p>
      </div>
      <div>
        <h4 className="font-readex_medium mt-10 text-2xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Your Consumer Privacy Rights
            </li>
          </ul>
        </h4>
        <p className="mt-5 text-lg">
          Under the applicable U.S. Privacy Laws, Consumers possess the following rights, which can be exercised directly or, in certain cases, through an authorized agent:
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Right to Limit Sensitive PI Processing:
            </li>
          </ul>
        </h6>
        <p className="mt-5 text-lg">
          Depending on your state of residency, you may have the right to instruct businesses to restrict their utilization and disclosure of Sensitive PI if it extends beyond certain internal business purposes. Should this right be applicable, we will interpret such a request as a withdrawal of any consent you may have provided for the processing of Sensitive PI.
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Right to Know:
            </li>
          </ul>
        </h6>
        <p className="mt-5 text-lg">
          Categories: If you are a California resident, you retain the right to request specific information regarding our collection, usage, and disclosure of your PI over the preceding 12-month period pertaining to categories of PI. This includes the right to ascertain whether we are processing your personal information and to receive information on the categories of PI collected, sources of PI, business purposes for collection or sale, categories of PI disclosed for business purposes, and categories of PI sold or shared.
        </p>
        <p className="mt-5 text-lg">
          Specific Pieces: You hold the right to request a portable copy of the specific pieces of PI collected about you. Please note that the retention period for PI varies, and thus, certain information collected about you may not be retained for 12 months and may not be included in our response.
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Right to Confirm:
            </li>
          </ul>
        </h6>
        <p className="mt-5 text-lg">
          You have the right to confirm whether we are processing your PI and to access your PI, as previously described.
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Right to Delete:
            </li>
          </ul>
        </h6>
        <p className="mt-5 text-lg">
          You possess the right to request the deletion of some or all of the PI we have about you. Following confirmation of your deletion request as a Verifiable Consumer Request, we will take steps to permanently erase your PI from our existing systems, de-identify your PI, or aggregate your PI with other information, subject to permitted retention exceptions.
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Right to Correct:
            </li>
          </ul>
        </h6>
        <p className="mt-5 text-lg">
          You may request corrections to inaccuracies in your PI maintained by Allignx.
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Do Not Sell/Share/Target:
            </li>
          </ul>
        </h6>
        <p className="mt-5 text-lg">
          Under various U.S. Privacy Laws, Consumers have the right to opt out of certain processing activities. Opt-out mechanisms are available for both cookie PI and non-cookie PI. Please refer to the respective sections above for instructions on opting out.
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Automated Decision Making and Profiling:
            </li>
          </ul>
        </h6>
        <p className="mt-5 text-lg">
          While we may engage in processing activities constituting automated decision-making or profiling under the CCPA, the updated regulations of the CCPA have not yet provided definitions for these concepts, nor have they outlined associated opt-out and access rights. Presently, we do not engage in profiling to produce legal or similarly significant effects. If our practices change, we will update this policy accordingly, providing you with the right to opt out of such activities as mandated by applicable U.S. Privacy Laws, subject to any exceptions.
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Right to Non-Discrimination:
            </li>
          </ul>
        </h6>
        <p className="mt-5 text-lg">
          You have the right not to receive discriminatory treatment for exercising your privacy rights, as prohibited by U.S. Privacy Laws.
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-xl  capitalize">
          <ul className="list-disc list-inside space-y-4 mt-4">
            <li>
              Right to Appeal:
            </li>
          </ul>
        </h6>
        <p className="mt-5 text-lg">
          Residents of certain states, such as Colorado, Connecticut, and Virginia, have the right to appeal a decision regarding a privacy request. Instructions for exercising this right will be provided in the response email containing our decision.
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-3xl  capitalize">

          NOTICE FOR NEVADA RESIDENTS

        </h6>
        <p className="mt-5 text-lg">
          Residents of Nevada hold the right to opt out of the sale of certain "covered information" gathered by operators of websites or online services. Currently, we do not engage in the sale of covered information as defined by this law, nor do we have intentions to do so in the future. However, if you wish to opt out of any potential future sale of personal information covered by this Act, you can provide us with your name and email address via support@allign.com. It is your responsibility to update any changes in your email address through the same method, and we are not obliged to cross-reference other emails you may have provided for different purposes. We will retain this information and contact you if our intentions change. At that point, we will establish a process for verifying your identity and providing verified consumers with the opportunity to complete their opt-out.
        </p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-3xl  capitalize">

          DATA PROTECTION PROVISIONS ABOUT THE APPLICATION AND USE OF TWILIO

        </h6>
        <p className="mt-5 text-lg">
          In order to enable secure authentication features within our services, such as one-time passwords (OTPs) sent via SMS or voice call, we utilize the services of a third-party communication provider, Twilio, Inc. Twilio's API allows us to integrate these OTP functionalities into our platform.
        </p>
        <p className="mt-5 text-lg">If you are a user of our services and choose to authenticate using a phone-based OTP, Twilio may need to collect and process certain personal information about you, such as your phone number, in order to deliver the OTP message. Twilio acts as an independent controller of this data, and their privacy practices are governed by Twilio's own privacy policy, which can be found at https://www.twilio.com/legal/privacy. We encourage you to review Twilio's privacy policy to understand how they may collect, use, and protect your personal information in the context of the services they provide to us.</p>
        <p className="mt-5 text-lg">We have implemented appropriate safeguards in our use of Twilio's services to protect your personal information and ensure it is processed in accordance with data protection laws. However, the handling of your data by Twilio is outside of our direct control. If you have any questions or concerns about Twilio's privacy practices, please contact them directly.</p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-3xl  capitalize">

          PAYMENT METHOD: DATA PROTECTION PROVISIONS ABOUT THE USE OF STRIPE AS A PAYMENT PROCESSOR

        </h6>
        <p className="mt-5 text-lg">
          On this Platform, the controller has integrated components of Stripe. Stripe is an online payment service provider. Payments are processed via so-called Stripe accounts, which represent virtual private or business accounts. Stripe is also able to process virtual payments through credit cards when a user does not have a Stripe account. A Stripe account is managed via an e-mail address, which is why there are no classic account numbers. Stripe makes it possible to trigger online payments to third parties or to receive payments. Stripe also accept trustee functions and offers buyer protection services.
        </p>
        <p className="mt-5 text-lg">Stripe’s services in Europe are provided by a Stripe affiliate—Stripe Payments Europe Limited (“Stripe Payments Europe”)—an entity located in Ireland. In providing Stripe Services, Stripe Payments Europe transfers personal data to Stripe, Inc.</p>
        <p className="mt-5 text-lg">If the data subject chooses “Stripe” as the payment option on the Platform, we automatically transmit the data of the data subject to Stripe accordingly. By selecting these payment options, the data subject agrees to the transfer of personal data required for payment processing.</p>
        <p className="mt-5 text-lg">The personal data transmitted to Stripe is usually first name, last name, address, email address, IP address, telephone number, mobile phone number, or other data necessary for payment processing. The processing of the purchase contract also requires such personal data, which are in connection with the respective order.</p>
        <p className="mt-5 text-lg">The transmission of the data is aimed at payment processing and fraud prevention. The controller will transfer personal data to Stripe, in particular, if a legitimate interest in the transmission is given. The personal data exchanged between Stripe and the controller for the processing of the data will be transmitted by Stripe to economic credit agencies. This transmission is intended for identity and creditworthiness checks.</p>
        <p className="mt-5 text-lg">Stripe will, if necessary, pass on personal data to affiliates and service providers or subcontractors to the extent that this is necessary to fulfil contractual obligations or for data to be processed in the order.</p>
        <p className="text-lg mt-5">The data subject has the possibility to revoke consent for the handling of personal data at any time from Stripe. A revocation shall not have any effect on personal data which must be processed, used or transmitted in accordance with (contractual) payment processing.</p>
        <p className="mt-5 text-lg">The applicable data protection provisions of Stripe may be retrieved under https://stripe.com/en-in/privacy.</p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-3xl  capitalize">

          DATA PROTECTION PROVISIONS ABOUT THE APPLICATION AND USE OF TAXRATE.IO

        </h6>
        <p className="mt-5 text-lg">
          In order to facilitate accurate tax calculations and compliance within our services, we utilize the services of a third-party tax rate provider, Taxrate.io.  Taxrate.io's API allows us to seamlessly integrate up-to-date tax rate information into our platform.
        </p>
        <p className="mt-5 text-lg">If you are a user of our services, Taxrate.io may need to collect and process certain personal information about you, such as your location data, in order to provide accurate tax rates. Taxrate.io acts as an independent controller of this data, and their privacy practices are governed by Taxrate.io's own privacy policy, which can be found at https://www.taxrate.io/privacy-policy. We encourage you to review Taxrate.io's privacy policy to understand how they may collect, use, and protect your personal information in the context of the services they provide to us.</p>
        <p className="mt-5 text-lg">We have implemented appropriate safeguards in our use of Taxrate.io's services to protect your personal information and ensure it is processed in accordance with data protection laws. However, the handling of your data by Taxrate.io is outside of our direct control. If you have any questions or concerns about Taxrate.io's privacy practices, please contact them directly.</p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-3xl  capitalize">

          DATA PROTECTION PROVISIONS ABOUT THE APPLICATION AND USE OF GOOGLE GEOCODE API

        </h6>
        <p className="mt-5 text-lg">
          In order to enhance our address validation and geocoding features within our services, we utilize the Google Geocode API. This API allows us to convert addresses into geographic coordinates and vice versa, ensuring accurate location data for our users.
        </p>
        <p className="mt-5 text-lg">If you are a user of our services, the Google Geocode API may need to collect and process certain personal information about you, such as your address, to provide accurate geocoding results. Google acts as an independent controller of this data, and their privacy practices are governed by Google’s own privacy policy, which can be found at https://policies.google.com/privacy?hl=en-US. We encourage you to review Google's privacy policy to understand how they may collect, use, and protect your personal information in the context of the services they provide to us.</p>
        <p className="mt-5 text-lg">We have implemented appropriate safeguards in our use of Google's services to protect your personal information and ensure it is processed in accordance with data protection laws. However, the handling of your data by Google is outside of our direct control. If you have any questions or concerns about Google's privacy practices, please contact them directly.</p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-3xl  capitalize">

          PERIOD FOR WHICH THE PERSONAL DATA WILL BE STORED

        </h6>
        <p className="mt-5 text-lg">
          We maintain your data for the duration of your account's existence. Upon closure of your account, we will proceed to delete your data. However, certain information may be retained for specific purposes. This includes logs of automatically gathered data for internal analytics and security measures,your email address, tax information, communications exchanged with you, and transactional details for auditing, tax, and financial considerations. Once there is no longer a business necessity to retain your data, we will either delete it or anonymize it accordingly.
        </p>
        <p className="mt-5 text-lg">Should we receive legal process related to your account, we will retain your data for as long as we reasonably believe is necessary to comply with the legal requirements. Likewise, if we suspect that your account has been engaged in wrongful activities, we may preserve your data to protect or assert our rights.</p>
      </div>
      <div>
        <h6 className="font-readex_medium mt-10 text-3xl  capitalize">

          PROVISION OF PERSONAL DATA AS STATUTORY OR CONTRACTUAL REQUIREMENT; REQUIREMENT NECESSARY TO ENTER INTO A CONTRACT; OBLIGATION OF THE DATA SUBJECT TO PROVIDE THE PERSONAL DATA; POSSIBLE CONSEQUENCES OF FAILURE TO PROVIDE SUCH DATA

        </h6>
        <p className="mt-5 text-lg">
          We clarify that the provision of personal data is partly required by law (e.g. tax regulations) or can also result from contractual provisions (e.g. information on the contractual partner). Sometimes it may be necessary to conclude a contract that the data subject provides us with personal data, which must subsequently be processed by us. The data subject is, for example, obliged to provide us with personal data when our company signs a contract with him or her. The non-provision of the personal data would have the consequence that the contract with the data subject could not be concluded. Before personal data is provided by the data subject, the data subject must contact us. We will clarify to the data subject whether the provision of the personal data is required by law, contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and the consequences of non-provision of the personal data.
        </p>

      </div>
      <div>
        <h2 className="font-readex_medium mt-10 text-3xl capitalize">
          CONTACT US
        </h2>
        <p className="mt-5 text-lg">
          If you have any queries on any aspect of our Privacy Policy, please contact us using the details provided below: <p>
            E-mail: support@allignx.com
          </p>
        </p>
      </div>
    </div>
  );
};

export default Privacy;
