import React from "react";

export const Terms = () => {
  return (
    <div className="min-h-screen py-40 max-w-[1500px] lg:max-w-[768px] mx-auto font-readex_regular px-4">
      <h1 className="font-readex_medium md:text-[24px] text-[44px] text-center capitalize">
        Terms and <span className="text-[#3E46FE]">Conditions</span>
      </h1>

      <h2 className="font-readex_medium mt-10 text-3xl ">INTRODUCTION</h2>
      <p className="mt-5 text-lg">
        Welcome to Vin technologies LLC. We are a company based in the USA.
        These Terms of Use regulate the use and access of the Allignx platform,
        and the services offered through the Allignx platform (“Platform”). For
        the sake of convenience, the term Platform shall also include the
        services offered through the Platform.
      </p>
      <p className="mt-5 text-lg">
        These Terms of Use along with the agreements we make available from time
        to time on our Platform (Collectively “Terms”) set out the legal
        obligation vis-à-vis Terms of Use for your interaction and usage of the
        Platform. By clicking “Accept” when prompted on the Platform or making
        an Account or, using or accessing the Platform, you understand that you
        will adhere to these Terms and all other operating rules, policies, and
        procedures that may be issued periodically on the Platform by us, each
        of which is incorporated by reference periodically by us. If you do not
        agree to any of these Terms, please do not use or access the Platform.
      </p>
      <p className="mt-5 text-lg">
        By accepting these Terms, you agree that the Terms constitute a binding
        contract, effective as of the date of first acceptance by you, between
        Vin technologies LLC (hereinafter referred to as “Allignx”, “we”, “us”,
        or “our”) and you the user (hereinafter referred to as “user”, “you”,
        “your”) (each a “Party” to the contract and collectively, the
        “Parties”).
      </p>
      <p className="mt-5 text-lg lowercase">
        BY USING THE ALLIGNX PLATFORM, YOU AGREE THAT ANY DISPUTE OR CLAIM
        ARISING OUT OF OR RELATING TO THESE TERMS OR THE PLATFORM WILL BE
        RESOLVED ON AN INDIVIDUAL BASIS. YOU WAIVE ANY RIGHT TO PARTICIPATE IN A
        CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION AGAINST ALLIGNX. THIS
        MEANS THAT YOU CANNOT BRING A CLAIM AS A PLAINTIFF OR CLASS MEMBER IN
        ANY PURPORTED CLASS, COLLECTIVE, OR REPRESENTATIVE PROCEEDING.
        ADDITIONALLY, UNLESS BOTH YOU AND ALLIGNX AGREE OTHERWISE, ARBITRATORS
        MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS AND MAY NOT OTHERWISE
        PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. WE ARE
        NOT RESPONSIBLE FOR ANY DISPUTES BETWEEN THE USERS AND THE SERVICE
        PROVIDERS.
      </p>

      <h2 className="font-readex_medium mt-10 text-3xl ">DEFINITIONS</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <strong>Account(s) : </strong>
          <span>The user account created by the users of the Platform</span>
        </li>
        <li>
          <strong>User(s) : </strong>
          <span>
            User who registers on the Platform and solicits short-term Service
            Provider Services.
          </span>
        </li>
        <li>
          <strong>Service Provider(s) : </strong>
          <span>
            User who registers on the Platform as a service provider, to be
            hired by the Users.
          </span>
        </li>
        <li>
          <strong>Service Provider Services : </strong>
          <span>Services offered by a Service Provider.</span>
        </li>
        <li>
          <strong> Service Request : </strong>
          <span>
            A Service Request is an opportunity created by a User which is
            subsequently completed by the Service Providers. A Service Request
            creates a contractual relationship between the User and the
            respective Service Provider. Vin technologies LLC or any of its
            representatives are not party to such contractual relationship.
          </span>
        </li>
        <li>
          <strong> User : </strong>
          <span>Who creates an Account on our Platform.</span>
        </li>
        <li>
          <strong> User Content : </strong>
          <span>
            Means any comments, remarks, data, feedback, content, text,
            photographs, images, video, music, or other information that any
            user posts to any part of the Platform. Besides the definitions
            contained in this section, the Terms may contain definitions
            throughout the main body of the Terms.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl "> How it works</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <strong>Creating a Service Request : </strong>
          <span>
            Users have the ability to create a Service Request within the
            Allignx Platform for a variety of service categories, including but
            not limited to plumbing, electrical work, and appliance repairs. To
            initiate a Service Request, users must provide specific details
            about the service required, which may include a description of the
            issue, location details, and any other relevant information.
          </span>
        </li>
        <li>
          <strong>Matching with Service Providers : </strong>
          <span>
            Once a Service Request is submitted, the Allignx Platform utilizes
            its matching algorithm to search for and identify available service
            providers in the user’s vicinity. The Platform ensures that the
            Service Request is assigned to a qualified Service Provider based on
            availability, proximity, and expertise. The User agrees to pay an
            initial one-time payment, which serves as a Service Fee (defined
            below) for finding a Service Provider and matching them as per your
            requirement. This Service Fee is non-refundable and shall be
            displayed on the Platform when you make a Service Request. This
            Service Fee shall be in addition to any fee chargeable for the
            Service Provider visit to your location if applicable which shall be
            charged at the time when you place a Service Request via the
            Platform (“Request Fee”) The User shall, after assessment of the
            work to be done by the Service Provider, be provided a quotation for
            the Service Provider Services. The User shall accept the final
            quotation before any work commences via the Platform. If the User
            does not accept the quotation, no further obligations will exist
            between the parties, and the Service Provider shall not commence any
            work. Notwithstanding anything contained herein, the Service Fee is
            non-refundable unless a refund is provided by us in our sole
            discretion.
          </span>
        </li>
        <li>
          <strong>Service Delivery : </strong>
          <span>
            Upon receiving an assignment, the Service Provider shall visit the
            user’s specified address at the agreed-upon time to perform the
            requested Service Provider Services.
          </span>
        </li>
        <li>
          <strong>Payment : </strong>
          <span>
            Following the completion of the Service Provider Services, the User
            is required to make a payment through the Allignx Platform.
          </span>
        </li>
        <li>
          <strong> Service Fee : </strong>
          <span>
            The Allignx Platform charges a service fee, that is calculated as a
            percentage of the total service cost. In addition to the
            aforementioned ,this fee covers the operational expenses of the
            Platform and ensures the continued provision of high-quality service
            (“Service Fee”). You shall be charged the Service Fee as may be
            displayed on the Platform and the Request Fee when you place a
            Service Request (subject to change at the sole discretion of
            Allignx).
          </span>
        </li>
        <li>
          <strong> Photo Upload : </strong>
          <span>
            Users shall have the option to upload photographs of the issue that
            needs to be addressed when creating a Service Request. These images
            assist the service provider in understanding the problem more
            accurately and preparing for the service visit.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl "> Our role</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            The Platform is not an employment agency service or business, and
            Allignx is not an employer of any User. Users are not employees,
            partners, representatives, agents, joint venturers, independent
            contractors, or franchisees of Allignx.
          </span>
        </li>
        <li>
          <span>Users hereby acknowledge and agree that Allignx does not:</span>
        </li>
        <li>
          <span>
            Perform Service Requests or employ individuals to perform Service
            Requests,
          </span>
        </li>
        <li>
          <span>
            Supervise, scope, direct, control, or monitor service providers’
            work, including setting their work locations, work hours, or terms
            of work, nor provide tools or supplies to, or pay any expenses of,
            service providers,
          </span>
        </li>
        <li>
          <span>
            Have any control over the quality, timing, legality, failure to
            provide, or any other aspect whatsoever of Service Provider Services
            of Service Providers (or their acts or omissions), nor over the
            integrity, responsibility, competence, qualifications,
            communications, or the ratings or reviews provided by Users or
            Service Providers with respect to each other.
          </span>
        </li>
        <li>
          <span>
            The formation of a service agreement between the Users and the
            Service Providers will not, under any circumstances, create any
            responsibility or liability for Allignx, nor any employment or other
            relationship between Allignx and the Users or between the Client and
            the service provider. Users do not have authority to, and may not
            act as agents for, nor bind or make any representations on behalf
            of, Allignx (including that service providers may not modify all or
            any part of our fees).
          </span>
        </li>
        <li>
          <span>
            Allignx is neither responsible nor liable for workers’ compensation
            or any tax payment or withholding, including but not limited to
            applicable sales taxes, unemployment or employment insurance, social
            security contributions, or other applicable payroll withholdings in
            connection with a User’s use of the Platform, or personal income
            tax.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        Service Provider Services Disclaimer
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            Service Providers utilizing the Allignx Platform operate as
            independent business owners, offering services under their own name
            or business name rather than under the Allignx name. They use their
            own tools and supplies. Service Providers set their own rates
            without any deductions by Allignx. Additionally, Service Providers
            have the freedom to:
          </span>
        </li>
        <li>
          <span>
            Maintain their own client base without any restrictions from
            Allignx,
          </span>
        </li>
        <li>
          <span>
            Provide their services on other platforms, including competing ones,
          </span>
        </li>
        <li>
          <span>
            Accept or decline clients and service agreements as they see fit.
            Service Providers are independent contractors working directly for
            the Users, meaning the Users are customers of the Service Providers,
            not of Allignx with respect to the Service Provider Services.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl "> PLATFORM USAGE</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            You hereby through this reference agree to comply with these Terms
            when accessing/using the Platform. A limited right is granted to you
            for accessing/using the Platform. This right is available as long as
            you adhere to our Terms.
          </span>
        </li>
        <li>
          <span>
            We strive to keep our Platform available for you to use 24x7. We
            also ensure that our Platform shall be accessible and secure always,
            however, we cannot guarantee the perpetual
            accessibility/availability of the Platform. We may cease to provide
            or make available certain features of our Platform without any
            notice to you.
          </span>
        </li>
        <li>
          <span>
            We may repeal the limited right granted to you concerning the
            use/access to the Platform by providing notice to you. The
            rescission of the right shall be effective immediately upon us
            providing such notice.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        ACCOUNT REGISTRATION
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            To access the Platform, you need to register for a user Account on
            the Platform. For continuous access to our Platform, it is suggested
            that you provide us with accurate, complete, and updated information
            wherever applicable. Failing to meet the aforesaid condition may
            result in the suspension of the respective user Account.
          </span>
        </li>
        <li>
          <span>
            You agree not to (1) misrepresent yourself as someone else by
            selecting or using a username a name, email, or phone number of
            another person; (2) use, as a username, an offensive, vulgar, or
            obscene name; (3) use as a username a fictitious name or pseudonym.
          </span>
        </li>
        <li>
          <span>
            You are solely liable and responsible for any activity that occurs
            on your Account. You agree and understand that you shall not share
            your user Account password with anybody or do any such act that
            promotes unauthorized use of your user Account. You shall take all
            measures to protect your password including but not limited to
            restricting the use of your personal device.
          </span>
        </li>
        <li>
          <span>
            You must notify us immediately on our Platform of any change in your
            eligibility to use the Platform, breach of security, or unauthorized
            use of your Account. You shall have the ability to delete your
            Account, either through the Platform or through a Service Request
            made on our Platform.
          </span>
        </li>
        <li>
          <span>
            You understand and agree that by creating a user Account you agree
            to receive communication concerning marketing emails and SMS from
            us. You understand and agree that any communication or notification
            you receive from us electronically shall qualify as legal notice and
            meet all the legal notice requirements.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl "> LICENSE</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            Subject to the Terms, Allignx gives you a limited, revocable,
            non-sublicensable, non-exclusive, and non-transferable license to
            the Platform and Platform Content only for purposes of using the
            Platform in accordance with these Terms. It is expressly prohibited
            without the prior express permission from Allignx to use, reproduce,
            modify, distribute, or store any Platform Content for purposes other
            than using the Platform consistent with these Terms.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl "> TERM</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            By accessing the Platform, creating an Account, and clicking
            “accept” whenever prompted means that you’ve officially “signed”
            these Terms.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl "> ELIGIBILITY</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            To use our Platform, you must: Be at least of the age of majority as
            per your local laws and not disqualified from entering into
            contracts under any law; Complete the registration process; Agree to
            our Terms; and Provide true, complete, and up-to-date legal and
            contact information If applicable, you represent and warrant that
            you have the authority to accept these Terms on behalf of the
            company you may be affiliated with. By using Platform, you represent
            and warrant that you will use Platform for only legitimate purposes.
            By using Platform, you represent and warrant that you meet all the
            requirements listed above, and that you will not use Platform in a
            way that violates any laws or regulations. Allignx may refuse
            service, close the Accounts of any users, and change eligibility
            requirements at any time. By accepting these Terms, you represent
            and warrant that you are qualified concerning the conditions stated
            herein and, therefore, are permitted to use the Platform. If you do
            not meet any of the conditions stated herein you shall not
            access/use the Platform and must cease to be a user.
          </span>
        </li>
        <li>
          <span>
            The User may be asked while registering and creating an Account on
            the Platform to submit their information including (without limits)
            [their cell phone number, email address, location or address, and
            payment details which include bank account details, card details,
            photo identification, and other financial details] required for
            making payment on the Platform as well as any information for tax
            purposes.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        PROCEDURAL TERMS FOR THE USERS
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            Users can create Service Requests on their Accounts. Subsequently,
            when a Service Request is created on the Platform, Service Providers
            can view created Service Requests.
          </span>
        </li>
        <li>
          <span>
            A Service Request may be denied by Allignx due to a violation of
            these Terms, which may include (but are not limited to) the
            following violations and/or materials:
          </span>
        </li>
        <li>
          <span> Illegal or Fraudulent Service Requests</span>
        </li>
        <li>
          <span>
            Copyright Infringement, Trademark Infringement, and violation of a
            third party's terms of service reported through our Intellectual
            Property Claims.
          </span>
        </li>
        <li>
          <span>
            Adult-oriented Service Requests, Prostitution, Pornographic,
            Inappropriate/Obscene.
          </span>
        </li>
        <li>
          <span> Intentional copies of Service Requests.</span>
        </li>
        <li>
          <span> Spam, Nonsense, or Violent Service Requests.</span>
        </li>
        <li>
          <span> Service Requests misleading to Service Providers.</span>
        </li>
        <li>
          <span> Reselling of regulated goods.</span>
        </li>
        <li>
          <span>
            Any other reason which in our opinion violates the policies of the
            Platform.
          </span>
        </li>
        <li>
          <span>
            Service Requests that are denied for violations are not eligible to
            be restored or edited.
          </span>
        </li>
        <li>
          <span>
            The prices posted on the Platform are non-negotiable. The Users
            cannot under any circumstance bargain with the Service Providers for
            any reason. In such an event Service Providers may report such Users
            to Allignx. We may take appropriate action against such Users
            (including without limits, removal of the Users’ account from the
            Platform) in our sole discretion.
          </span>
        </li>
        <li>
          <span>
            The Users shall not offer direct payments to Service Providers using
            third-party payment systems or other payment systems outside of the
            Platform.
          </span>
        </li>
        <li>
          <span>
            Allignx retains the right to use all Platform published delivered
            services including services of Service Providers for our marketing
            and promotional purposes.
          </span>
        </li>
        <li>
          <span>
            TO PROTECT AGAINST FRAUD, UNAUTHORIZED TRANSACTIONS (SUCH AS MONEY
            LAUNDERING), CLAIMS OR OTHER LIABILITIES, WE DO NOT COLLECT CREDIT
            INFORMATION; BUT ALLOW OUR PAYMENT VENDORS TO COLLECT INFORMATION
            FOR THE PURPOSE OF COLLECTING PAYMENTS FROM USERS ON THE PLATFORM OR
            TRANSFERRING PAYMENTS TO USERS ON THE PLATFORM. WE ARE NOT EXPOSED
            TO THE PAYMENT INFORMATION PROVIDED TO OUR PAYMENT VENDORS, AND THIS
            INFORMATION IS SUBJECT TO THE PRIVACY POLICY APPLICABLE TO THE
            PAYMENT VENDOR.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        USERS REPRESENTATIONS AND WARRANTIES
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            Users shall engage Service Providers for personal, household,
            business and related services. Users represent that the purpose for
            which they hire the Service Provider is legitimate, lawful purposes
            only.
          </span>
        </li>
        <li>
          <span>
            Users understand and agree they are exclusively responsible for all
            payments via the Platform. These payments relate to services availed
            by the Users through this Platform and our third-party payment
            processor vendors.
          </span>
        </li>
        <li>
          <span>
            Users understand and agree they are responsible for paying any
            direct or indirect taxes, including any state or local taxes,
            GST/HST/PST, VAT or otherwise, which may apply to them depending on
            residency or location. The prices listed on the Platform shall be
            inclusive of all such taxes and charges that may apply to the
            Users/Service Providers unless specified by us otherwise in writing.
          </span>
        </li>
        <li>
          <span>
            Users understand and agree that they shall treat Service Providers
            as independent contractors and the Users are the only ones
            responsible for and assume all liability regarding employment
            categorization of the Service Providers including categorization as
            employees of the Users. Users agree and acknowledge that Allignx has
            no control, supervision, direction, or decision-making authority,
            for Service Providers and/or Service Provider’s personnel other than
            a Service Provider’s access and use of the Platform.
          </span>
        </li>
        <li>
          <span>
            Service Providers and Users are responsible for determining any
            contract terms between User and Service Provider including without
            limitation pay rate, work hours, service dates, and working
            conditions.
          </span>
        </li>
        <li>
          <span>
            Users shall only use the designated third-party payment service
            provider (PSP) for making or receiving payments for Service
            Requests.
          </span>
        </li>
        <li>
          <span>
            Users shall comply with all applicable local, state, provincial,
            national, or international laws when using the Platform.
          </span>
        </li>
        <li>
          <span>
            Users shall not use the Platform for purchasing or delivering
            alcohol or any controlled or illegal substances or services.
          </span>
        </li>
        <li>
          <span>
            Users shall keep all communications regarding Service Requests
            (including scoping, payments, and any relevant questions) on the
            Platform, before, during, and after the Service Request.
          </span>
        </li>
        <li>
          <span>
            User agrees and understands to keep an active email address and
            phone number and to make the aforesaid address or number available
            where applicable to Platform and Service Provider. Users authorize
            Allignx to share their contact information which Allignx has in its
            possession with Service Providers registered on this Platform.
          </span>
        </li>
        <li>
          <span>
            Users understand that the Platform does not guarantee the accuracy
            and validity of any User Content. It does not vouch for any User
            Content posted on the Platform.
          </span>
        </li>
        <li>
          <span>
            Users represent and warrant to not engage with the Service Providers
            outside of the Platform. Doing so may result in permanent Account
            termination.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        AGREEMENT BETWEEN USER AND SERVICE PROVIDER{" "}
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            Users and Service Providers shall enter into a contract (“Service
            Request Contract”) related to the performance of the Service Request
            for which Users seek to hire the Service Provider. This Service
            Request Contract is an agreement entered between the Users and
            Service Provider which lays the Terms of Use of the Service Request
            to be performed by the Service Provider for the Users. The Service
            Request Contract is independent of Allignx.
          </span>
        </li>
        <li>
          <span>
            Users and Service Providers have complete discretion in deciding
            whether they should enter into the Service Request Contract and also
            decide the conditions of the Service Request Contract. You
            understand that Allignx is not a party to the Service Request
            Contract and the Service Providers are not employees of Allignx.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl "> PERSONAL DATA </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            You agree that your personal data is collected by us through your
            consent. Please refer to our Privacy Policy to know how we collect
            and use your personal data.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        APPLE AND ANDROID DEVICES
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            The following terms apply when you use a mobile application obtained
            from either the Apple Store or Google Play (each an “App
            Distributor”) to access our Platform:
          </span>
        </li>
        <li>
          <span>
            the license granted to you for our Platform is limited to a
            non-transferable license to use the application on a device that
            utilizes the Apple iOS or Android operating systems, as applicable,
            and in accordance with the usage rules set forth in the applicable
            App Distributor’s terms of service;
          </span>
        </li>
        <li>
          <span>
            we are responsible for providing any maintenance and support
            services with respect to the mobile application as specified in the
            terms and conditions of this mobile application license contained in
            these Terms or as otherwise required under applicable law, and you
            acknowledge that each App Distributor has no obligation whatsoever
            to furnish any maintenance and support services with respect to the
            mobile application;
          </span>
        </li>
        <li>
          <span>
            in the event of any failure of the mobile application to conform to
            any applicable warranty, you may notify the applicable App
            Distributor, and the App Distributor, in accordance with its terms
            and policies, may refund the purchase price, if any, paid for the
            Platform, and to the maximum extent permitted by applicable law, the
            App Distributor will have no other warranty obligation whatsoever
            with respect to the Platform;
          </span>
        </li>
        <li>
          <span>
            you represent and warrant that (i) you are not located in a country
            that is subject to a U.S. government embargo, or that has been
            designated by the U.S. government as a “terrorist supporting”
            country and (ii) you are not listed on any U.S. government list of
            prohibited or restricted parties
          </span>
        </li>
        <li>
          <span>
            you must comply with applicable third-party terms of agreement when
            using the mobile application, e.g., if you have a VoIP application,
            then you must not be in violation of their wireless data service
            agreement when using the mobile application;
          </span>
        </li>
        <li>
          <span>
            you acknowledge and agree that the App Distributors are third-party
            beneficiaries of the terms and conditions in this Platform license
            contained in these Terms, and that each App Distributor will have
            the right (and will be deemed to have accepted the right) to enforce
            the terms and conditions in this Platform license contained in these
            Terms against you as a third-party beneficiary thereof.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        EXTERNAL SERVICE PURCHASES
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            You may have the option to pay for in-app purchases through an
            External Service, such as with your Apple ID or Google account
            (“your External Service Account”), and your External Service Account
            will be charged for the purchase in accordance with the terms
            disclosed to you at the time of purchase and the general terms
            applicable to your External Service Account. Some External Services
            may charge you sales tax, which may change from time to time.
          </span>
        </li>
        <li>
          <span>
            If your External Service Purchase includes an automatically renewing
            subscription, your External Service Account will continue to be
            periodically charged for the subscription until you cancel. After
            your initial subscription commitment period, and again after any
            subsequent subscription period, the subscription will automatically
            continue for the price and time period you agreed to when
            subscribing.
          </span>
        </li>
        <li>
          <span>
            To cancel a subscription: If you do not want your subscription to
            renew automatically, or if you want to change or terminate your
            subscription, you must log in to your External Service Account and
            follow instructions to manage or cancel your subscription, even if
            you have otherwise deleted your account with us or if you have
            deleted the Platform from your device.
          </span>
        </li>
        <li>
          <span>
            For example, if you subscribed using your Apple ID, cancellation is
            handled by Apple, not us. To cancel a purchase made with your Apple
            ID, go to Settings {">"} iTunes & App Stores {">"} [click on your
            Apple ID] {">"} View Apple ID {">"} Subscriptions, then find your
            subscription and follow the instructions to cancel. You can also
            request assistance at https://getsupport.apple.com.
          </span>
        </li>
        <li>
          <span>
            Similarly, if you subscribed on Google Play, cancellation is handled
            by Google. To cancel a purchase made through Google Play, launch the
            Google Play app on your mobile device and go to Menu {">"} My Apps{" "}
            {">"} Subscriptions, then find your subscription and follow the
            instructions to cancel. You can also request assistance at
            https://play.google.com. If you cancel a subscription, you may
            continue to use the cancelled service until the end of your
            then-current subscription term. The subscription will not be renewed
            when your then-current term expires.
          </span>
        </li>
        <li>
          <span>
            If you initiate a chargeback or otherwise reverse a payment made
            with your External Service Account, we may terminate your Account
            immediately in our sole discretion, on the basis that you have
            determined that you do not want our subscription. In the event that
            your chargeback or other payment reversal is overturned, please
            contact us using the information at the end of this page. We will
            retain all funds charged to your External Service Account until you
            cancel your subscription through your External Service Account.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        CLAIMS FOR COPYRIGHT INFRINGEMENT
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            Allignx Platform respects the intellectual property rights of others
            and expects its users to do the same. In accordance with the Digital
            Millennium Copyright Act (“DMCA”), Allignx Platform will respond
            expeditiously to claims of copyright infringement committed using
            the Allignx Platform website and/or app if such claims are reported
            to Allignx Platform’s. Upon receipt of a notice alleging copyright
            infringement, Allignx Platform will take whatever action it deems
            appropriate within its sole discretion, including removal of the
            allegedly infringing materials and termination of access for repeat
            infringers of copyright-protected content.
          </span>
        </li>
        <li>
          <span>
            Reporting Claims of Copyright Infringement If you believe that any
            content residing on or accessible through the Allignx Platform
            website or app infringes a copyright, please send a notice of
            copyright infringement containing the following information to us.
          </span>
        </li>
        <li>
          <span>
            Identification of the copyrighted work claimed to have been
            infringed, or, if multiple copyrighted works are covered by a single
            notification, a representative list of such works.
          </span>
        </li>
        <li>
          <span>
            Identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled, and information reasonably
            sufficient to permit Allignx Platform to locate the material.
            Providing URLs in the body of an email is the best way to help us
            locate content quickly.
          </span>
        </li>
        <li>
          <span>
            Information reasonably sufficient to permit Allignx Platform to
            contact the complaining party, such as an address, telephone number,
            and, if available, an email address at which the complaining party
            may be contacted.
          </span>
        </li>
        <li>
          <span>
            A statement that the complaining party has a good faith belief that
            use of the material in the manner complained of is not authorized by
            the copyright owner, its agent, or the law.
          </span>
        </li>
        <li>
          <span>
            A statement that the information in the notification is accurate,
            and under penalty of perjury, that the complaining party is
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed.
          </span>
        </li>
        <li>
          <span>
            A physical or electronic signature of a person authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed. Please note that under Section 512(f) of the DMCA, any
            person who knowingly materially misrepresents that material or
            activity is infringing may be subject to liability.
          </span>
        </li>
        <li>
          <strong> Counter-Notification Procedures</strong>
          <span>
            If the recipient of a notice of alleged copyright infringement
            believes that the notice is erroneous or false, and that the
            allegedly infringing material was removed or access to it was
            disabled as a result of mistake or misidentification, the recipient
            may file a counter-notification with Allignx.
          </span>
        </li>
        <li>
          <span>
            A valid counter-notification must include the following information:
            Identification of the material that has been removed or to which
            access has been disabled and the location at which the material
            appeared before it was removed or access to it was disabled.
          </span>
        </li>
        <li>
          <span>
            A statement under penalty of perjury that the recipient has a good
            faith belief that the material was removed or disabled as a result
            of mistake or misidentification of the material to be removed or
            disabled.
          </span>
        </li>
        <li>
          <span>
            A physical or electronic signature of the recipient. Upon receipt of
            a counter-notification, Allignx Platform may reinstate the removed
            material at its sole discretion.
          </span>
        </li>
        <li>
          <span>
            Allignx Platform will provide a copy of the counter-notification to
            the original complaining party, informing that party that the
            removed material may be reinstated within 10 business days. Unless
            the copyright owner files an action seeking a court order against
            the alleged infringer, the removed material will be reinstated, or
            access to it restored, within 10 to 14 business days after receipt
            of the counter-notification.
          </span>
        </li>
        <li>
          <span>
            Please note that filing a false counter-notification may result in
            legal consequences, including perjury charges.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        INTELLECTUAL PROPERTY
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            The Platform contains the Intellectual Property of Allignx in the
            form of content, graphics, videos, audio, text, and any other
            digital content (“Platform Content”). This is an agreement for the
            use of Platform, and you are not granted a license to any Platform
            Content under the Terms. Except to the extent that applicable laws
            prevent us from doing so, you will not, directly or indirectly: (i)
            reverse engineer, decompile, disassemble, or otherwise attempt to
            discover the source code, object code, or underlying structure,
            ideas, or algorithms of, or found at or through the Platform; (ii)
            remove any proprietary notices or labels from the Platform Content;
            reproduce or copy the Platform Content or any part thereof; (iii)
            modify, translate, or create derivative works based on the Platform
            Content; (iv) copy, distribute, pledge, assign, or otherwise
            transfer or encumber rights to the Platform Content; (v) create any
            derivative product from any of the foregoing; (vi) without our
            express written permission, introduce automated agents or scripts to
            the Platform so as to produce multiple Accounts, generate automated
            searches, requests and queries, or to strip or mine data from the
            Platform; or (vii) allow third parties to gain access to the
            Platform or to Platform Content in any manner other than as
            expressly permitted in these Terms.
          </span>
        </li>
        <li>
          <span>
            You acknowledge and agree that the Platform, the names and logos and
            all related product and names, design marks and slogans, and all
            other material comprising the Platform, are the property of the
            Allignx or its affiliates with the exception of the Service
            Providers marks (collectively, the “Marks”). Unless stated
            otherwise, all Marks are protected as the copyright, trade dress,
            trademarks and/or other intellectual properties owned by us or by
            other parties that have licensed their material to us. You are not
            authorized to use any of the Marks in any advertising, publicity, or
            any other commercial manner without the prior written consent of
            Allignx. Your use of the Platform confers no title or ownership in
            the Platform or the Marks and is not a sale of any rights in the
            Platform or the Marks. All ownership rights remain in Allignx or its
            third-party suppliers, as the case may be.
          </span>
        </li>
        <li>
          <span>
            It is our Policy to limit access to our Platform of users who
            infringe the intellectual property rights of others, as a
            consequence of which we shall terminate your Account. If you find
            that anything on our Platform infringes any copyright that you own,
            or control please contact us using the information provided below.
          </span>
        </li>
        <li>
          <span>
            We do not claim any ownership rights in any User Content and nothing
            in these Terms will be deemed to restrict any rights that you may
            have to use and exploit your User Content.
          </span>
        </li>
        <li>
          <span>
            You are solely responsible for any User Content that you submit,
            publish, transmit, or display on, through, or with our Platform. You
            grant us a non-exclusive, worldwide, royalty-free, and fully paid
            license to use the User Content, as necessary, for purposes of
            providing the Platform services to you. All rights in and to the
            User Content not expressly granted to us in these Terms are reserved
            by You. The User Content shall hereby through this reference form
            part of the Platform Content. The User Content is owned by their
            respective owners or as permitted in this Terms of Use.
          </span>
        </li>
        <li>
          <span>
            By making any User Content available through Platform you hereby
            grant to us a non-exclusive, transferable, worldwide, license to
            use, copy, modify, and distribute your User Content in connection
            with operating and providing the Platform services to you and to
            other Account holders.
          </span>
        </li>
        <li>
          <span>
            You are solely responsible for all your User Content. You represent
            and warrant that neither your User Content, nor your use and
            provision of your User Content to be made available through the
            Platform, nor any use of your User Content by us on or through the
            Platform will infringe, misappropriate or violate a third party’s
            intellectual property rights, or rights of publicity or privacy, or
            result in the violation of any applicable law or regulations.
          </span>
        </li>
        <li>
          <span>
            You can remove your User Content by specifically deleting it.
            However, in certain instances, some of your User Content may not be
            completely removed and copies of your User Content may continue to
            exist on the Platform. We are not responsible or liable for the
            removal or deletion of (or the failure to remove or delete) any of
            your User Content.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        THIRD PARTY SERVICES
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            The Platform may permit you to link to other websites, services, or
            resources on the Internet, and other websites, services or resources
            may contain links to the Platform. Also, Platform Content may
            contain links to other websites, services, or resources on the
            Internet. When you access third party resources on the Internet, you
            shall do so at your own risk. These other resources are not
            controlled by us, and you agree that we shall not be responsible or
            liable for including but not limited to the content, functions,
            accuracy, legality, appropriateness or any other aspect of such
            websites or resources. The inclusion of any such link shall not
            imply our endorsement or any association in any way between us and
            their operators. You also agree that we will not be responsible or
            liable in any case, either directly or indirectly, for any damage or
            loss caused or alleged to be caused by or in connection with the use
            of or reliance on any such content, goods, or services available on
            or through any such website or resource.
          </span>
        </li>
      </ul>
      <h2 className="font-readex_medium mt-10 text-3xl "> TERMINATION</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            We reserve the right to terminate your access to all or any part of
            the Platform at any point or time, without providing any cause, with
            or without notice, effective immediately, which may result in the
            forfeiture and destruction of all information associated with your
            membership. You may terminate your Account if you wish to do so by
            placing a request on our Platform. Any such termination shall
            immediately revoke the license(s) granted by us under these Terms,
            and you shall effective immediately be prohibited from accessing or
            using the Platform and Platform Content for any reason. The
            provisions of these Terms which by their nature should survive
            termination shall survive termination, including but not limited to
            Licenses, warranty disclaimers, ownership provisions, limitations of
            liability and indemnification.
          </span>
        </li>
      </ul>
      <h2 className="font-readex_medium mt-10 text-3xl "> RELEASE</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            To the maximum extent permissible by applicable law, you hereby
            absolutely release Allignx and its affiliates as well as all other
            users of the Platform from responsibilities including but not
            limited to, claims, causes of action, liability, expenses, demands,
            and/or damages (actual and consequential) of all kinds and nature,
            known and unknown and claims of negligence, that may arise from the
            use of or inability to use, or in relation to your use of and/or
            reliance on the Platform, including any disputes which may arise
            between users and the acts or omissions of third parties.
          </span>
        </li>
      </ul>
      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        WARRANTY DISCLAIMER
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            THE PLATFORM IS PROVIDED “AS IS,” “AS AVAILABLE” BASIS. THE USE OF
            PLATFORM IS AT THE USER’S SOLE RISK. THE PLATFORM IS PROVIDED
            WITHOUT WARRANTY, REPRESENTATION, OR GUARANTEE OF ANY KIND
            WHATSOEVER, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
            TO,  ANY WARRANTIES OF TITLE OR ACCURACY AND ANY IMPLIED WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT AND ANY WARRANTIES IMPLIED BY ANY COURSE OF
            PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
            DISCLAIMED, WITH THE SOLE EXCEPTION OF WARRANTIES (IF ANY) WHICH
            CANNOT BE EXPRESSLY EXCLUDED UNDER APPLICABLE LAW. ALLIGNX, OUR
            DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS, AND CONTENT
            PROVIDERS DO NOT WARRANT THAT: (I) THE PLATFORM IS OR WILL BE SECURE
            OR AVAILABLE AT ANY PARTICULAR TIME, INSTANCE OR LOCATION; (II) ANY
            DEFECTS MATERIAL OR NOT, OR ERRORS WILL BE CORRECTED; (III) ANY/ALL
            CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE PLATFORM IS FREE OF
            VIRUSES OR OTHER HARMFUL COMPONENTS; (IV) ANY/ALL INFORMATION IS
            COMPLETE, ACCURATE, UP-TO-DATE, OR RELIABLE; (V) ANY PARTICULAR
            SERVICE, CONTENT, OR PRODUCT REFERRED TO IN THE PLATFORM IS SAFE,
            APPROPRIATE, OR EFFECTIVE FOR YOU AND/OR YOUR EMPLOYEES; (VI) THAT
            RESULTS OF USING THE PLATFORM WILL MEET YOUR REQUIREMENTS(VII) THE
            USE OF THE PLATFORM PROVIDED BY US SHALL COMPLY WITH ANY LAWS,
            RULES, REGULATIONS, REQUIREMENTS, POLICIES, QUALIFICATIONS, OR BEST
            PRACTICES, INCLUDING BUT NOT LIMITED TO PRIVACY LAWS, PROFESSIONAL
            LICENSURE, OR REIMBURSEMENT; (VIII) THE USE OF THE PLATFORM SHALL
            NOT RESULT IN LEGAL DUTIES OR LIABILITY. WE DO NOT GUARANTEE IN ANY
            INSTANCE THAT ANY PARTICULAR CONTENT OR MATERIAL SHALL BE MADE
            AVAILABLE THROUGH THE PLATFORM.
          </span>
        </li>
      </ul>
      <h2 className="font-readex_medium mt-10 text-3xl "> INDEMNIFICATION</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            You acknowledge and agree that, you shall at all times defend,
            indemnify, and hold harmless us, our affiliates and each of our and
            our affiliates’ including but not limited to, respective officers,
            directors, contractors, employees, agents, suppliers, and
            representatives against all liabilities, claims, fees, costs,
            penalties or sanctions, losses, expenses, and interest of any
            nature, including reasonable attorneys’ fees, arising out of or
            which may relate to: (a) your use or misuse of, or access to, the
            Platform; (b)your violation of any privacy, professional, ethics,
            licensing, or consumer protection laws, rules, or regulations; (c)
            your misuse of anyone’s private, proprietary, or Personal data; (d)
            infringement by you (or any third party using your Account or
            identity in the Services) of any intellectual property or other
            right of any person or entity; or (e) otherwise in violation of
            these Terms in any way. It is our right to assume the exclusive
            defence and control of any matter otherwise subject to
            indemnification by you, in which event you shall assist and
            cooperate with us in asserting any available defences at your
            expense, including reasonable attorneys’ fees incurred by us.
          </span>
        </li>
      </ul>
      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        LIMITATION OF LIABILITY
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            THE USE OF THE PLATFORM IS ENTIRELY AT YOUR OWN RISK. IN NO CASE
            SHALL WE, NOR OUR OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS,
            AGENTS, PARTNERS, SUPPLIERS, CONTENT PROVIDERS, OR ANY USERS BE
            LIABLE TO YOU UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR
            ANY OTHER LEGAL OR EQUITABLE THEORY WITH REGARDS TO THE SERVICES
            FOR: (I) ANY LOST PROFITS, LOSS IN REVENUE, LOSS OF GOODWILL, ANY
            DATA LOSS, LOSS OF USE, COST OF PROCURING SUBSTITUTE GOODS OR
            SERVICES, OTHER INTANGIBLE LOSSES, OR INDIRECT, INCIDENTAL, SPECIAL,
            PUNITIVE, COMPENSATORY, EXEMPLARY, RELIANCE, PUNITIVE, LIQUIDATED,
            OR ANY SIMILAR CONSEQUENTIAL DAMAGES OF ANY TYPE WHATSOEVER (HOWEVER
            ARISING), (II) ANY, VIRUSES, BUGS, TROJAN HORSES, OR THE LIKE
            (REGARDLESS OF THE SOURCE OF ORIGIN), (III) ANY PERSONAL INJURY OR
            HARM, INCLUDING DEATH, WHICH IS CAUSED BY YOUR USE OR MISUSE OF THE
            PLATFORM, (IV) ANY CLAIMS, DEMANDS, OR DAMAGES ARISING OUT OF OR
            RELATING TO ANY DISPUTE BETWEEN YOU AND ANY OTHER USER OF THE
            PLATFORM, OR (V) ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE)
            OF OR GREATER THAN ANY FEES PAID BY YOU FOR USING OF THE PLATFORM
            DURING THE IMMEDIATELY PREVIOUS THREE (3) MONTH PERIOD. REMEDIES
            UNDER THESE TERMS ARE EXCLUSIVE AND ARE LIMITED TO THOSE EXPRESSLY
            PROVIDED FOR IN THESE TERMS. NOTHING IN THESE TERMS SHALL BE DEEMED
            TO EXCLUDE OR LIMIT YOUR LIABILITY IN RESPECT OF ANY INDEMNITY GIVEN
            BY YOU UNDER THESE TERMS.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl ">
        {" "}
        Electronic Transactions and Signatures
      </h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span> By using the Platform, you agree to the following:</span>
        </li>
        <li>
          <span>
            You consent to conduct transactions electronically through the
            Platform.
          </span>
        </li>
        <li>
          <span>
            Your electronic signature is legally equivalent to your handwritten
            signature and holds the same legal effect, validity, and
            enforceability as a traditional paper-based signature.
          </span>
        </li>
        <li>
          <span>
            Your use of a keypad, mouse, or other devices to select an item,
            button, icon, or perform a similar action constitutes your
            electronic signature, as if you had signed in writing.
          </span>
        </li>
        <li>
          <span>
            No certification authority or third-party verification is required
            to validate your electronic signature, and the absence of such
            certification or verification does not affect the enforceability of
            your electronic signature.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl "> MODIFICATION</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            We shall have the right to make modifications or replace any of the
            Terms, or suspend, change, or discontinue the Platform (including
            but not limited to, the availability of any feature content, or
            database,) at any time or instance by posting a notice through the
            Platform. We may also do so by sending you a notice via e-mail, via
            the Platform, or by any other means of communication. We reserve the
            right to impose limits on certain features and Platform. We may, if
            required to do so restrict your access to parts or all of the
            Platform without notice or liability. We endeavour to try and
            provide notice of modifications to these Terms. However, you also
            agree that it is also your responsibility to take reasonable efforts
            to be aware of such modifications.
          </span>
        </li>
        <li>
          <span>
            When you continue to use the Platform after notification of any
            modifications to the Terms shall mean acceptance of those
            modifications, and those modifications shall apply to your continued
            use of the Platform going forward. Your use of the Platform is
            subject to the Terms in effect at the time of such use.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl "> CHOICE OF LAW</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            These Terms, the construction and enforcement of its terms and the
            interpretation of the rights and duties of the Parties hereto shall
            be governed by the laws of the state of Montana and shall be subject
            to the jurisdiction of courts in Montana. This Agreement is executed
            in English language which shall prevail over any translation
            thereof.
          </span>
        </li>
      </ul>
      <h2 className="font-readex_medium mt-10 text-3xl "> MISCELLANEOUS</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>
            Entire agreement and severability. These Terms are the entire
            agreement between you and us with regards to the Platform. These
            Terms supersede all prior contemporaneous communications and
            proposals made (whether oral, written, or electronic) between you
            and us with regards to the Platform. If any provisions mentioned in
            these Terms are found to be unenforceable or invalid, that
            particular provision or provisions will be limited or eliminated to
            the minimum extent necessary so that these Terms will otherwise
            remain in full force and effect and enforceable. In the event of the
            failure of either Party to exercise in any respect any right
            provided for herein shall not be deemed a waiver of any further
            rights hereunder.
          </span>
        </li>
        <li>
          <span>
            Force majeure. We will not be liable in any case for any failure or
            delay in the performance of our obligations for any reason hereunder
            if such failure results from: (a) any cause beyond our reasonable
            control, including but not limited to, mechanical, electronic or
            communications failure or degradation, denial-of-service attacks,
            (b) any failure by a third party hosting provider or utility
            provider, (c) strikes, shortages, riots, fires, acts of God, war,
            terrorism, pandemics, epidemics and governmental action.
          </span>
        </li>
        <li>
          <span>
            Assignment. You agree that these Terms are personal to you, and are
            not assignable, transferable or sublicensable by you. We reserve the
            right to assign, transfer or delegate any of our rights and
            obligations hereunder without obtaining consent.
          </span>
        </li>
        <li>
          <span>
            Notices. All notices under these Terms shall be in writing Unless
            otherwise specified in these Term. Notices to us shall be sent by
            email to [ENTER]. You shall ensure written confirmation of receipt
            for notice to be effective. Notices to you shall be sent to your
            last known email address (or the email address of your successor, if
            any) and/or to any email address that would be reasonably likely to
            provide notice to you, and such notice shall be effective upon
            transmission.
          </span>
        </li>
        <li>
          <span>
            No waiver. Our failure to enforce any part of these Terms shall not
            constitute a waiver of our right to later enforce that or any other
            part of these Terms. Waiver of compliance in any particular instance
            does not mean that we will waive compliance in the future.
          </span>
        </li>
        <li>
          <span>
            Interpretation. The headers are provided only to make this agreement
            easier to read and understand.
          </span>
        </li>
      </ul>

      <h2 className="font-readex_medium mt-10 text-3xl "> CONTACT</h2>
      <ul className="list-disc list-inside mt-5 space-y-4 text-lg">
        <li>
          <span>You may contact us at:</span> <br />
          <br />
          <a href="mailto:support@allignx.com" className="mt-10 underline">
            support@allignx.com
          </a>
        </li>
      </ul>
    </div>
  );
};
