import { Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import YouTube from "react-youtube";
import { data,data2,data3 } from '../../../helper/Chart';

const AnalysisTab = () => {
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{
                    backgroundColor: "#fff",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "10px"
                }}>
                    <p className="label" style={{ fontWeight: "bold" }}>{`${label}`}</p>
                    <p style={{ color: "#8884d8" }}>{`On-site ser. Req.: ${payload[0].value}`}</p>
                    <p style={{ color: "#82ca9d" }}>{`Virtual Con. Ser. Req.: ${payload[1].value}`}</p>
                    <p className="desc">{`Date: 22 August, 2019`}</p>
                </div>
            );
        }

        return null;
    };

  return (
    <>
    <div className='flex xl:flex-col justify-between  gap-10 '>
        <div className='w-1/2  xl:w-full rounded-lg p-10 md:p-5 ' style={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}>
            <div className='flex md:flex-col justify-center gap-5'>
                <div className='md:flex flex-wrap items-center justify-between'>
                    <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg'>Earning by category</h1>
                    <div className='flex justify-start items-center gap-5 mt-3'>
                        <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$4,673</span>
                        <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                    </div>
                </div>
                <div>

                    <div className='md:hidden'>
                        <ResponsiveContainer width={400} height={250}>
                            <BarChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="On-site ser. Req." fill="#304FFD" className='text-black' />
                                <Bar dataKey="Virtual Con. Ser. Req." fill="#304FFD1A" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div className='md:block hidden'>
                        <ResponsiveContainer width='100%' height={250}>
                            <BarChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="On-site ser. Req." fill="#304FFD" className='text-black' />
                                <Bar dataKey="Virtual Con. Ser. Req." fill="#304FFD1A" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>

        <div className='w-1/2 xl:w-full  rounded-lg p-10' style={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}>
            <div className='flex md:flex-col justify-center gap-5'>
                <div>
                    <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg'>Earning (Day-week-Month) </h1>
                    <div className='md:grid grid-cols-2 xs:grid-cols-1'>
                        <div>
                            <div className='text-[#4B465C] text-xs mt-3'>Day Earing</div>
                            <div className='flex justify-start items-center gap-5 mt-1'>
                                <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$250</span>
                                <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                            </div>
                        </div>

                        <div>
                            <div className='text-[#4B465C] text-xs mt-5'>Weekly Earing</div>
                            <div className='flex justify-start items-center gap-5 mt-1'>
                                <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$2,150</span>
                                <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+08.2%</span>
                            </div>
                        </div>

                        <div>
                            <div className='text-[#4B465C] text-xs mt-5'>Monthly Earing</div>
                            <div className='flex justify-start items-center gap-5 mt-1'>
                                <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$60,350</span>
                                <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+04.2%</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='md:hidden'>
                        <PieChart width={400} height={300} margin={{ top: 0, right: 5, left: 8, bottom: 0 }}>
                            <Pie
                                data={data2}
                                dataKey="pv"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                innerRadius={100}
                                outerRadius={130}
                                fill="#3E46FE"
                            // label
                            >
                                {data2.map((entry, index) => (<Cell key={`cell-${index}`} fill={entry.color} />))}
                            </Pie>
                            {/* Add text in the center */}
                            <text
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fontSize="24px"
                                fontWeight="bold"
                                fill="#4B465C"
                            >
                                $60,350
                            </text>
                            <text
                                x="50%"
                                y="60%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fontSize="18px"
                                fontWeight="bold"
                                fill="#3E46FE"
                            >
                                Total
                            </text>
                        </PieChart>
                    </div>
                    {/* </ResponsiveContainer> */}

                    <div className='hidden md:block'>
                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart width={400} height={300} margin={{ top: 0, right: 5, left: 8, bottom: 0 }}>
                                <Pie
                                    data={data2}
                                    dataKey="pv"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={70}
                                    outerRadius={90}
                                    fill="#3E46FE"
                                // label
                                >
                                    {data2.map((entry, index) => (<Cell key={`cell-${index}`} fill={entry.color} />))}
                                </Pie>
                                {/* Add text in the center */}
                                <text
                                    x="50%"
                                    y="50%"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    fontSize="24px"
                                    fontWeight="bold"
                                    fill="#4B465C"
                                >
                                    $60,350
                                </text>
                                <text
                                    x="50%"
                                    y="60%"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    fontSize="18px"
                                    fontWeight="bold"
                                    fill="#3E46FE"
                                >
                                    Total
                                </text>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className='w-1/2  xl:w-full rounded-lg p-10 md:p-5 mx-auto mt-10' style={{
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    }}>
        <div className='flex md:flex-col justify-center gap-5'>
            <div className='md:flex flex-wrap items-center justify-between'>
                <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg'>Earning by Ser. Req.</h1>
                <div className='flex justify-start items-center gap-5 mt-3'>
                    <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$4,673</span>
                    <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                </div>
            </div>
            <div>
                <div className='md:hidden'>
                    <LineChart data={data3}
                        width={430} height={250}
                        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Line type="monotone" dataKey="onsite" stroke="#8884d8" dot={{ r: 6 }} />
                        <Line type="monotone" dataKey="virtual" stroke="#82ca9d" dot={{ r: 6 }} />
                    </LineChart>
                </div>
                {/* </ResponsiveContainer> */}
                <div className='md:block hidden'>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={data3}
                            width={430} height={250}
                            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Line type="monotone" dataKey="onsite" stroke="#8884d8" dot={{ r: 6 }} />
                            <Line type="monotone" dataKey="virtual" stroke="#82ca9d" dot={{ r: 6 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    </div>

    {/* Video Tutorial */}
    <h1 className='text-center text-black text-3xl py-20 font-readex_bold'>Video  <span className='text-primary'> Tutorial</span></h1>
    <YouTube
        videoId='lbxJ4K5MD2o'
        id='lbxJ4K5MD2o'
        iframeClassName="sm:w-[590px] w-[790px] h-[500px] rounded-[20px]"
        className="flex w-full justify-center "
    />


    {/* How it’s works */}
    <h1 className='text-center text-black text-3xl py-20 font-readex_bold'>How it’s  <span className='text-primary'> works </span></h1>
    <div className='grid grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>

        <div className='text-[#A6A6A6] rounded-lg p-10 md:p-5' style={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}>
            <h2 className='text-black font-readex_semibold text-center ' > Sign Up </h2>
            <div className='text-center'>Create your profile in minutes.</div>
        </div>

        <div className='text-[#A6A6A6] rounded-lg p-10 md:p-5 text-center' style={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}>
            <div className='text-black font-readex_semibold'> List Your Services </div>
            <div>Create your profile in minutes.</div>
        </div>

        <div className='text-[#A6A6A6] rounded-lg p-10 md:p-5 text-center' style={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}>
            <div className='text-black font-readex_semibold'> Get Matched  </div>
            <div>Create your profile in minutes.</div>
        </div>

        <div className='text-[#A6A6A6] rounded-lg p-10 md:p-5 text-center' style={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }} >
            <div className='text-black font-readex_semibold'>Complete Jobs  </div>
            <div>Create your profile in minutes.</div>
        </div>
    </div>
    {/* <div className='grid grid-cols-3 gap-5'>

<div className='text-[#A6A6A6]'>
    <strong className='text-black font-readex_regular'>1 Sign Up: </strong> Create your profile in minutes.
</div>

<div className='text-[#A6A6A6] '>
    <strong className='text-black font-readex_regular'>2 List Your Services : </strong> Create your profile in minutes.
</div>

<div className='text-[#A6A6A6] '>
    <strong className='text-black font-readex_regular'>3 Get Matched : </strong> Create your profile in minutes.
</div>

<div className='text-[#A6A6A6] '>
    <strong className='text-black font-readex_regular'>4 Complete Jobs:  </strong> Create your profile in minutes.
</div>
</div> */}


    {/*Why AlignX ? */}
    <h1 className='text-center text-black text-3xl py-20 font-readex_bold'>Why <span className='text-primary'> AlignX ? </span></h1>
    <div className='grid grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>


        <div className='text-[#A6A6A6] rounded-lg p-5 border border-[#EEEEEE]' >
            <div className='flex gap-4  items-center'>
                <div className='flex-none'>
                    <img src="/assets/svg/people.svg" alt="image" width={36} height={36} className='size-9' />
                </div>
                <div>
                    <h2 className='text-primary text-base font-readex_semibold ' > Easy booking</h2>
                    <div className='text-sm mt-1.5'>Be seen by thousands of potential customers.</div>
                </div>
            </div>
        </div>

        <div className='text-[#A6A6A6] rounded-lg p-5 border border-[#EEEEEE]' >
            <div className='flex gap-4  items-center'>
                <div className='flex-none'>
                    <img src="/assets/svg/wallet.svg" alt="image" width={36} height={36} className='size-9' />
                </div>
                <div>
                    <h2 className='text-primary text-base font-readex_semibold ' > Verified professionals</h2>
                    <div className='text-sm mt-1.5'>Get paid quickly and securely after each job.</div>
                </div>
            </div>
        </div>

        <div className='text-[#A6A6A6] rounded-lg p-5 border border-[#EEEEEE]' >
            <div className='flex gap-4  items-center'>
                <div className='flex-none'>
                    <img src="/assets/images/time.png" alt="image" width={36} height={36} className='size-9' />
                </div>
                <div>
                    <h2 className='text-primary text-base font-readex_semibold ' >Transparent pricing</h2>
                    <div className='text-sm mt-1.5'>Choose when and where you want to work.</div>
                </div>
            </div>
        </div>

        <div className='text-[#A6A6A6] rounded-lg p-5 border border-[#EEEEEE]' >
            <div className='flex gap-4  items-center'>
                <div className='flex-none'>
                    <img src="/assets/images/lifebuoy.png" alt="image" width={36} height={36} className='size-9' />
                </div>
                <div>
                    <h2 className='text-primary text-base font-readex_semibold ' >customer support</h2>
                    <div className='text-sm mt-1.5'>Access 24/7 customer support and a wealth of resources to help you succeed.</div>
                </div>
            </div>
        </div>
    </div>
</>
  )
}

export default AnalysisTab