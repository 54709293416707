
import { Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { FaChevronDown } from 'react-icons/fa';
import { data, data2, data4, data5 } from '../../../helper/Chart';
import Chart from 'react-apexcharts';

const Earning = () => {
    const options = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: false, // Hides the top icons (toolbar)
              },
        },
        stroke: {
            curve: 'stepline', // Stepline curve for the chart
        },
        dataLabels: {
            enabled: false, // Disables data labels on the chart
        },
       
        markers: {
            hover: {
                sizeOffset: 4, // Increases marker size on hover
            },
        },
        xaxis: {
            categories: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10', 'C11'],
            labels: {
                show: false, // Hides the C1, C2, ..., C11 labels
              },
        },
        yaxis: {
            show: false, // Hides the Y-axis
          },
        colors: ['#3E46FE'],
    };

    const series = [
        {
            data: [34, 44, 54, 21, 12, 43, 33, 23, 66, 66, 58], // Your data array
        },
    ];

    return (
        <>
            <div className='flex xl:flex-col justify-between  gap-10 '>

                <div className='w-1/2  xl:w-full rounded-lg p-10 md:p-5 ' style={{
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                }}>
                    <div className='flex md:flex-col justify-center gap-5'>
                        <div className='md:flex flex-wrap items-center justify-between'>
                            {/* <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg'>Daily Earning</h1> */}

                            <Menu>
                                <MenuButton className="inline-flex items-center gap-2    text-[#4B465C] font-medium font-readex_medium text-lg ">
                                    Daily Earning
                                    <FaChevronDown className="size-4" />
                                </MenuButton>

                                <MenuItems
                                    transition
                                    anchor="bottom end"
                                    className="w-52 origin-top-right rounded-xl border  bg-white p-1 text-sm/6 text-black transition  duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                                >
                                    <MenuItem>
                                        <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                            Edit
                                            <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘E</kbd>
                                        </button>
                                    </MenuItem>
                                    <MenuItem>
                                        <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                            Duplicate
                                            <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘D</kbd>
                                        </button>
                                    </MenuItem>
                                    <div className="my-1 h-px bg-white" />
                                    <MenuItem>
                                        <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                            Archive
                                            <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘A</kbd>
                                        </button>
                                    </MenuItem>
                                    <MenuItem>
                                        <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                            Delete
                                            <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘D</kbd>
                                        </button>
                                    </MenuItem>
                                </MenuItems>
                            </Menu>

                            <div className='flex justify-start items-center gap-5 mt-3'>
                                <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$4,673</span>
                                <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                            </div>
                        </div>
                        <div>  <div>
                            <div className='md:hidden'>
                                <PieChart width={400} height={300} margin={{ top: 0, right: 5, left: 8, bottom: 0 }}>
                                    <Pie
                                        data={data2}
                                        dataKey="pv"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={100}
                                        outerRadius={130}
                                        fill="#3E46FE"
                                    // label
                                    >
                                        {data2.map((entry, index) => (<Cell key={`cell-${index}`} fill={entry.color} />))}
                                    </Pie>
                                    {/* Add text in the center */}
                                    <text
                                        x="50%"
                                        y="50%"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        fontSize="24px"
                                        fontWeight="bold"
                                        fill="#4B465C"
                                    >
                                        $60,350
                                    </text>
                                    <text
                                        x="50%"
                                        y="60%"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        fontSize="18px"
                                        fontWeight="bold"
                                        fill="#3E46FE"
                                    >
                                        Total
                                    </text>
                                </PieChart>
                            </div>
                            {/* </ResponsiveContainer> */}

                            <div className='hidden md:block'>
                                <ResponsiveContainer width="100%" height={400}>
                                    <PieChart width={400} height={300} margin={{ top: 0, right: 5, left: 8, bottom: 0 }}>
                                        <Pie
                                            data={data2}
                                            dataKey="pv"
                                            nameKey="name"
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={70}
                                            outerRadius={90}
                                            fill="#3E46FE"
                                        // label
                                        >
                                            {data2.map((entry, index) => (<Cell key={`cell-${index}`} fill={entry.color} />))}
                                        </Pie>
                                        {/* Add text in the center */}
                                        <text
                                            x="50%"
                                            y="50%"
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                            fontSize="24px"
                                            fontWeight="bold"
                                            fill="#4B465C"
                                        >
                                            $60,350
                                        </text>
                                        <text
                                            x="50%"
                                            y="60%"
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                            fontSize="18px"
                                            fontWeight="bold"
                                            fill="#3E46FE"
                                        >
                                            Total
                                        </text>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className='w-1/2 xl:w-full  rounded-lg p-10' style={{
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                }}>
                    <div className='flex md:flex-col justify-center gap-5'>
                        <div>
                            {/* <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg'>Weekly Earning </h1> */}
                            <Menu>
                                <MenuButton className="inline-flex items-center gap-2 rounded-md  text-[#4B465C] font-medium font-readex_medium text-lg ">
                                    Weekly Earning
                                    <FaChevronDown className="size-4" />
                                </MenuButton>

                                <MenuItems
                                    transition
                                    anchor="bottom end"
                                    className="w-52 origin-top-right rounded-xl border  bg-white p-1 text-sm/6 text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                                >
                                    <MenuItem>
                                        <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                            Edit
                                            <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘E</kbd>
                                        </button>
                                    </MenuItem>
                                    <MenuItem>
                                        <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                            Duplicate
                                            <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘D</kbd>
                                        </button>
                                    </MenuItem>
                                    <div className="my-1 h-px bg-white" />
                                    <MenuItem>
                                        <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                            Archive
                                            <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘A</kbd>
                                        </button>
                                    </MenuItem>
                                    <MenuItem>
                                        <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                            Delete
                                            <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘D</kbd>
                                        </button>
                                    </MenuItem>
                                </MenuItems>
                            </Menu>
                            <div className='flex justify-start items-center gap-5 mt-3'>
                                <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$4,673</span>
                                <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                            </div>
                        </div>

                        <div className='md:hidden'>
                            <ResponsiveContainer width={400} height={250}>
                                <BarChart data={data5}>
                                    <CartesianGrid strokeDasharray="3 3" strokeOpacity={0} />
                                    <XAxis dataKey="name" />
                                    <YAxis hide />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="On-site ser. Req." fill="#3E46FE29" className='text-black' />
                                    {/* <Bar dataKey="Virtual Con. Ser. Req." fill="#304FFD1A" /> */}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <div className='md:block hidden'>
                            <ResponsiveContainer width='100%' height={250}>
                                <BarChart data={data5}>
                                    <CartesianGrid strokeDasharray="3 3" strokeOpacity={0} />
                                    <XAxis dataKey="name" />
                                    <YAxis hide />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="On-site ser. Req." fill="#3E46FE29" className='text-black' />
                                    {/* <Bar dataKey="Virtual Con. Ser. Req." fill="#304FFD1A" /> */}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-1/2  xl:w-full rounded-lg p-10 md:p-5 mx-auto mt-10' style={{
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}>
                <div className='flex md:flex-col justify-center gap-5'>
                    <div className='md:flex flex-wrap items-center justify-between'>
                        {/* <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg'>Monthly Earning</h1> */}
                        <Menu>
                            <MenuButton className="inline-flex items-center gap-2    text-[#4B465C] font-medium font-readex_medium text-lg ">
                                Monthly Earning
                                <FaChevronDown className="size-4" />
                            </MenuButton>

                            <MenuItems
                                transition
                                anchor="bottom end"
                                className="w-52 origin-top-right rounded-xl border  bg-white p-1 text-sm/6 text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                            >
                                <MenuItem>
                                    <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                        Edit
                                        <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘E</kbd>
                                    </button>
                                </MenuItem>
                                <MenuItem>
                                    <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                        Duplicate
                                        <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘D</kbd>
                                    </button>
                                </MenuItem>
                                <div className="my-1 h-px bg-white" />
                                <MenuItem>
                                    <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                        Archive
                                        <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘A</kbd>
                                    </button>
                                </MenuItem>
                                <MenuItem>
                                    <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                        Delete
                                        <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘D</kbd>
                                    </button>
                                </MenuItem>
                            </MenuItems>
                        </Menu>
                        <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg mt-2'>September</h1>
                        <div className='flex justify-start items-center gap-5 mt-3'>
                            <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$4,673</span>
                            <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                        </div>
                    </div>
                    <div>
                        <div className='md:'>
                            {/* <LineChart data={data4} width={300} height={250}>
                                <CartesianGrid strokeDasharray="3 3" vertical={false} strokeOpacity={0}/>
                                <XAxis dataKey="name" hide />
                                <YAxis hide />
                                <Tooltip />
                                <Line type="monotone" dataKey="value" stroke="#4C6EF5" strokeWidth={4} dot={false} />
                            </LineChart> */}
                            <div id="chart">
                                <Chart options={options} series={series} type="line" height={350}  />
                            </div>
                        </div>
                        {/* <div className='md:hidden hidden'>
                            <ResponsiveContainer width="100%" height={150}>
                                <LineChart data={data4} width={300} height={400}>
                                    <CartesianGrid strokeDasharray="3 3" vertical={false} strokeOpacity={0} />
                                    <XAxis dataKey="name" hide />
                                    <YAxis hide />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="value" stroke="#4C6EF5" strokeWidth={4} dot={false} />
                                </LineChart>
                            </ResponsiveContainer>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Earning