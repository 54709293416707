import React, { useState } from "react";
import logo from "../images/logo.png";
import { HiMenu } from "react-icons/hi";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { GrFormClose } from "react-icons/gr";
import { useScroll } from "./ScrollContext";

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { scrollToAbout, scrollToServices, scrollToContact } = useScroll();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <div className="flex fixed w-full bg-white z-50 top-0 justify-between items-center lg:px-4 xl:px-8 px-40 py-3 border-b border-[#d9dbe9]">
        <a href="/">
          <img
            src={logo}
            alt="logo"
            className="w-[77px] md:w-[60px] md:h-[53px] h-[73.42px] "
          />
        </a>
        <HiMenu
          className="text-[21px] cursor-pointer md:flex hidden"
          onClick={() => setIsDrawerOpen(true)}
        />

        <div className="md:hidden flex lg:gap-10 gap-14 font-readex_regular">
          <a href="/">Home</a>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              scrollToAbout();
            }}
          >
            About
          </a>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              scrollToServices();
            }}
          >
            Services
          </a>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              scrollToContact();
            }}
          >
            Contact us
          </a>
        </div>
      </div>

      <Drawer
        open={isDrawerOpen}
        onClose={toggleDrawer}
        direction="right"
        className="chatDrawer"
      >
        <GrFormClose
          className="text-[24px] absolute right-3 top-5"
          onClick={toggleDrawer}
        />
        <div className="mt-5 w-full px-3">
          <img src={logo} alt="logo" className="size-1/3 " />

          <div className="flex flex-col gap-4 mt-8 font-readex_regular">
            <a href="#" onClick={() => setIsDrawerOpen(false)}>
              Home
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsDrawerOpen(false);
                scrollToAbout();
              }}
            >
              About
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsDrawerOpen(false);
                scrollToServices();
              }}
            >
              Services
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsDrawerOpen(false);
                scrollToContact();
              }}
            >
              Contact us
            </a>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
