import React from "react";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import { Outlet } from "react-router-dom";
import { ScrollProvider } from "./Component/ScrollContext";

export default function Layout() {
  return (
    <div>
      <ScrollProvider>
        <Header />
        <Outlet />
        <Footer />
      </ScrollProvider>
    </div>
  );
}
