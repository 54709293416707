export const data = [
    {
        "name": "C1",
        "Virtual Con. Ser. Req.": 4000,
        "On-site ser. Req.": 2400
    },
    {
        "name": "C2",
        "Virtual Con. Ser. Req.": 3000,
        "On-site ser. Req.": 1398
    },
    {
        "name": "C3",
        "Virtual Con. Ser. Req.": 2000,
        "On-site ser. Req.": 9800
    },
    {
        "name": "C4",
        "Virtual Con. Ser. Req.": 2780,
        "On-site ser. Req.": 3908
    },
    {
        "name": "C5",
        "Virtual Con. Ser. Req.": 1890,
        "On-site ser. Req.": 4800
    },
    {
        "name": "C6",
        "Virtual Con. Ser. Req.": 2390,
        "On-site ser. Req.": 3800
    },
    {
        "name": "C7",
        "Virtual Con. Ser. Req.": 3490,
        "On-site ser. Req.": 4300
    }
]
export const data2 = [
    {
        "name": "A",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400,
        "color": '#3E46FE'
    },
    {
        "name": "B",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210,
        "color": '#787EFF'
    },
    {
        "name": "C",
        "uv": 2000,
        "pv": 6500,
        "amt": 2290,
        "color": '#3E46FE'
    },
    {
        "name": "D",
        "uv": 2780,
        "pv": 3908,
        "amt": 2000,
        "color": '#787EFF'
    },
    {
        "name": "E",
        "uv": 1890,
        "pv": 4800,
        "amt": 2181,
        "color": '#3E46FE'
    },
    {
        "name": "F",
        "uv": 2390,
        "pv": 3800,
        "amt": 2500,
        "color": '#787EFF'
    },
    {
        "name": "G",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100,
        "color": '#3E46FE'
    }
]
export const data3 = [
    { name: 'C1', onsite: 400, virtual: 240 },
    { name: 'C2', onsite: 800, virtual: 139 },
    { name: 'C3', onsite: 1000, virtual: 980 },
    { name: 'C4', onsite: 2780, virtual: 3908 },
    { name: 'C5', onsite: 1890, virtual: 4800 },
    { name: 'C6', onsite: 2390, virtual: 3800 },
    { name: 'C7', onsite: 3490, virtual: 4300 },
];
export const data4 = [
    { name: 'Week 1', value: 400 },
    { name: 'Week 2', value: 300 },
    { name: 'Week 3', value: 600 },
    { name: 'Week 4', value: 500 },
    { name: 'Week 5', value: 700 }
];
export const data5 = [
    {
        "name": "M",
        "Virtual Con. Ser. Req.": 4000,
        "On-site ser. Req.": 2400
    },
    {
        "name": "T",
        "Virtual Con. Ser. Req.": 3000,
        "On-site ser. Req.": 1398
    },
    {
        "name": "W",
        "Virtual Con. Ser. Req.": 2000,
        "On-site ser. Req.": 9800
    },
    {
        "name": "T",
        "Virtual Con. Ser. Req.": 2780,
        "On-site ser. Req.": 3908
    },
    {
        "name": "F",
        "Virtual Con. Ser. Req.": 1890,
        "On-site ser. Req.": 4800
    },
    {
        "name": "S",
        "Virtual Con. Ser. Req.": 2390,
        "On-site ser. Req.": 3800
    },
    {
        "name": "S",
        "Virtual Con. Ser. Req.": 3490,
        "On-site ser. Req.": 4300
    }
]